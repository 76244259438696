<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('baseInfoIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('baseInfo2Intro') }}<br><br><u>{{ $t('baseInfoIntro2') }}</u></p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <form @submit.prevent="submit">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('nationality') }}</div>
            <v-select
              v-model="creationDocument.attributes.nationality"
              class="outlined__input gradient-border"
              :items="countries"
              outlined
            >
            </v-select>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('sex') }}</div>
            <v-select
              v-model="creationDocument.attributes.sex"
              class="outlined__input gradient-border"
              :items="sexes"
              outlined
            >
            </v-select>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" style="z-index: 1" cols="12" sm="12">
            <div class="question-marks">
              <div class="base-info-description mb-1 question-mark inline-block">{{ $t('middle_name') }}</div>
              <div class="question-mark inline-block ml-2">
                <v-tooltip top>
                  <template v-slot:activator="onMiddleName">
                    <img v-on="onMiddleName.on" src="@/assets/icons/question_tooltip.svg">
                  </template>
                  <span>{{ $t('middle_name_tooltip') }}</span>
                </v-tooltip>
              </div>
            </div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              v-model="creationDocument.attributes.middle_name"
            ></v-text-field>
            <div class='error-notice' v-if="creationDocument.attributes.middle_name && validateCyrillic(creationDocument.attributes.middle_name)">
              {{ $t('dont_use_cyrillic') }}
            </div>
            <div class='error-notice' v-if="creationDocument.attributes.middle_name && validateNumbers(creationDocument.attributes.middle_name)">
              {{ $t('dont_use_numbers') }}
            </div>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('maiden_name') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              v-model="creationDocument.attributes.maiden_name"
            ></v-text-field>
            <div class='error-notice' v-if="creationDocument.attributes.maiden_name && validateCyrillic(creationDocument.attributes.maiden_name)">
              {{ $t('dont_use_cyrillic') }}
            </div>
            <div class='error-notice' v-if="creationDocument.attributes.maiden_name && validateNumbers(creationDocument.attributes.maiden_name)">
              {{ $t('dont_use_numbers') }}
            </div>
          </v-col>
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="!validAllData || loading"
              type="submit"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            <v-progress-circular
              v-if="loading"
              :size="15"
              color="grey"
              indeterminate
            ></v-progress-circular>
            </button>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  data () {
    return {
      loading: false,
      countries: [
        { value: 'Pole', text: this.$i18n.t('Pole') },
        { value: 'Bulgarian', text: this.$i18n.t('Bulgarian') },
        { value: 'Romanian', text: this.$i18n.t('Romanian') },
        { value: 'Ukrainian', text: this.$i18n.t('Ukrainian') },
        { value: 'Russian', text: this.$i18n.t('Russian') },
        { value: 'Belarussian', text: this.$i18n.t('Belarussian') },
        { value: 'other', text: this.$i18n.t('other') }
      ],
      sexes: [
        { value: '', text: '' },
        { value: 'Woman', text: this.$i18n.t('woman') },
        { value: 'Man', text: this.$i18n.t('man') }
      ]
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return (this.creationDocument.attributes.middle_name == null || ((this.creationDocument.attributes.middle_name != null && !this.validateCyrillic(this.creationDocument.attributes.middle_name) && !this.validateNumbers(this.creationDocument.attributes.middle_name)))) &&
        (this.creationDocument.attributes.maiden_name == null || ((this.creationDocument.attributes.maiden_name != null && !this.validateCyrillic(this.creationDocument.attributes.maiden_name) && !this.validateNumbers(this.creationDocument.attributes.maiden_name))))
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    validateCyrillic (value) {
      const cyrillicPattern = /[\u0400-\u04FF]/
      return cyrillicPattern.test(value)
    },

    validateNumbers (value) {
      const numberPattern = /\d/
      return numberPattern.test(value)
    },

    submit () {
      this.loading = true
      const dataObject = {
        state: 'base_info2',
        client: {
          nationality: this.creationDocument.attributes.nationality,
          sex: this.creationDocument.attributes.sex,
          middle_name: this.creationDocument.attributes.middle_name,
          maiden_name: this.creationDocument.attributes.maiden_name
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'base_info2'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
</style>
