<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('baseInfoIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('baseInfoAdditionalPhoneIntro') }}<br><br><u>{{ $t('baseInfoAdditionalPhoneIntro2') }}</u></p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <form @submit.prevent="submit">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('phone') }}</div>
            <vue-phone-number-input
              class="base-form-input"
              :no-validator-state="true"
              :no-example="true"
              :translations="translations"
              :preferred-countries="['PL', 'RO', 'BG']"
              outlined
              @update="phone2Updated"
              v-model="creationDocument.attributes.phone2" />
            <div class='error-notice' v-if="creationDocument.attributes.phone2 && !validPhone">
              {{ $t('wrongPhoneRegistration') }}
            </div>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('email') }}</div>
            <v-text-field
              class="base-form-input"
              outlined
              hide-details="true"
              v-model="creationDocument.attributes.email2"
            ></v-text-field>
            <div class='error-notice' v-if="creationDocument.attributes.email2 && !validEmail">
              {{ $t('wrongEmailRegistration') }}
            </div>
            <div class='error-notice' v-if="creationDocument.attributes.email2 && validateCyrillic(creationDocument.attributes.email2)">
              {{ $t('dont_use_cyrillic') }}
            </div>
          </v-col>
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="!validAllData || loading"
              type="submit"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            <v-progress-circular
              v-if="loading"
              :size="15"
              color="grey"
              indeterminate
            ></v-progress-circular>
            </button>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  data () {
    return {
      loading: false,
      temporaryPhoneNumber: null,
      phoneIsValid: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return this.validEmail && this.validPhone
    },

    translations () {
      return {
        countrySelectorLabel: this.$t('countrySelectorLabel'),
        countrySelectorError: this.$t('countrySelectorError'),
        phoneNumberLabel: this.$t('phoneNumberLabel'),
        example: this.$t('example')
      }
    },

    validEmail () {
      if (this.creationDocument && this.creationDocument.attributes.email2) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return !!this.creationDocument.attributes.email2.match(emailRegex)
      } else {
        return true
      }
    },

    validPhone () {
      if (this.creationDocument && this.creationDocument.attributes.phone2) {
        const phoneRegex = /^\d{9}$/
        return !!this.creationDocument.attributes.phone2.replace(/\s+/g, '').match(phoneRegex)
      } else {
        return true
      }
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    validateCyrillic (value) {
      const cyrillicPattern = /[\u0400-\u04FF]/
      return cyrillicPattern.test(value)
    },

    phone2Updated (data) {
      this.temporaryPhoneNumber = data.formattedNumber
    },

    submit () {
      this.loading = true
      const dataObject = {
        state: 'base_info_additional_phone',
        client: {
          first_name: this.creationDocument.attributes.first_name,
          phone2: this.temporaryPhoneNumber,
          email2: this.creationDocument.attributes.email2
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'base_info_additional_phone'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .input-tel__input {
    background-color: rgba(180, 180, 180, 0.1) !important;
    border-radius: 10px !important;
    border: none !important;
    padding: 0 12px !important;
    min-height: 56px !important;
  }

  .input-country-selector {
    min-height: 56px !important;
  }

  .country-selector__country-flag {
    margin-top: 7px !important;
  }

  .country-selector__input {
    min-height: 56px !important;
  }

  .vue-phone-number-input .select-country-container {
    flex: 0 0 110px !important;
    width: 110px !important;
    min-width: 110px !important;
  }
</style>
