<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('maritalStatusIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('maritalStatusIntro') }}</p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('approveInformation') }}</h3>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <p cols="12" class="base-info-description text-left mt-4">{{ $t('verifyInformation') }}</p>
          </v-col>
        </v-row>
        <v-row v-if="currentChildren < 0" class="gradient-border-container pa-2">
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="6" sm="6">
                <div class="base-info-description mb-1">{{ $t('kidsSize') }}</div>
              </v-col>
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="6" sm="6">
                <div class="base-info-description text-right mb-1 gradient-pink semibold-font">{{ creationDocument.attributes.children.length }}</div>
              </v-col>
            </div>
            <div class="container mt-2" v-for="(child, index) in creationDocument.attributes.children" :key="child.id">
              <div class="row">
                <v-col class="mt-0 mb-2 pt-0 pb-0 ml-0 pl-0" cols="12" sm="12">
                  <p class="base-info-description mb-1">{{ $t((index + 1) + 'Child') }}:</p>
                  <p class="base-info-description mb-1"><strong>{{ child.first_name }} {{ child.last_name }}</strong></p>
                  <p class="base-info-description mb-1">{{ $t('sex') }}: {{ $t(child.sex) }}</p>
                  <p class="base-info-description mb-1">{{ $t('shortBirthday') }} {{ child.formated_birthday }}</p>
                </v-col>
                <v-col class="mt-0 mb-2 pt-0 pb-0 ml-0 pl-0 text-right" cols="12" sm="12">
                  <button @click="currentChildren = index" class="gradient-pink semibold-font">{{ $t('edit') }}</button>
                </v-col>
              </div>
            </div>
          </div>
        </v-row>
        <div class="row mt-4" v-if="currentChildren < 0">
          <v-col cols="12" lg="12" class="text-right">
            <button
              :disabled="loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
      <div v-if="currentChildren >= 0">
        <v-row>
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
                <div class="base-info-description mb-1">{{ $t('sex') }}</div>
              </v-col>
              <v-col cols="6" lg="6" class="text-left">
                <button :class="creationDocument.attributes.children[currentChildren].sex == 'Woman' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.children[currentChildren].sex = 'Woman'">{{ $t('woman') }}</button>
              </v-col>
              <v-col cols="6" lg="6" class="text-left">
                <button :class="creationDocument.attributes.children[currentChildren].sex == 'Man' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.children[currentChildren].sex = 'Man'">{{ $t('man') }}</button>
              </v-col>
            </div>
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
                <div class="base-info-description mb-1">{{ $t('firstName') }}</div>
                <v-text-field
                  class="base-form-input"
                  hide-details="true"
                  outlined
                  required
                  autofocus
                  v-model="creationDocument.attributes.children[currentChildren].first_name"
                ></v-text-field>
                <div class='error-notice' v-if="creationDocument.attributes.children[currentChildren].first_name && validateCyrillic(creationDocument.attributes.children[currentChildren].first_name)">
                  {{ $t('dont_use_cyrillic') }}
                </div>
                <div class='error-notice' v-if="creationDocument.attributes.children[currentChildren].first_name && validateNumbers(creationDocument.attributes.children[currentChildren].first_name)">
                  {{ $t('dont_use_numbers') }}
                </div>
              </v-col>
            </div>
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
                <div class="base-info-description mb-1">{{ $t('lastName') }}</div>
                <v-text-field
                  class="base-form-input"
                  hide-details="true"
                  outlined
                  required
                  autofocus
                  v-model="creationDocument.attributes.children[currentChildren].last_name"
                ></v-text-field>
                <div class='error-notice' v-if="creationDocument.attributes.children[currentChildren].last_name && validateCyrillic(creationDocument.attributes.children[currentChildren].last_name)">
                  {{ $t('dont_use_cyrillic') }}
                </div>
                <div class='error-notice' v-if="creationDocument.attributes.children[currentChildren].last_name && validateNumbers(creationDocument.attributes.children[currentChildren].last_name)">
                  {{ $t('dont_use_numbers') }}
                </div>
              </v-col>
            </div>
          </div>
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
                <div class="base-info-description mb-1">{{ $t('birthday') }}</div>
                <v-menu
                  ref="birthdayMenu"
                  v-model="birthdayMenu"
                  class="birthday-menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatBirthday"
                      prepend-inner-icon="mdi-calendar"
                      hint="DD/MM/YYYY"
                      persistent-hint
                      class="base-form-input"
                      hide-details="true"
                      outlined
                      required
                      readonly
                      @blur="creationDocument.attributes.children[currentChildren].birthday = parseDate(formatBirthday)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="creationDocument.attributes.children[currentChildren].birthday"
                    no-title
                    :locale="userLocale"
                    @input="handleDateSelection"
                  ></v-date-picker>
                </v-menu>
                <div class='error-notice' v-if="formatBirthday && !validBirthday()">
                  {{ $t('wrong_birthday') }}
                </div>
              </v-col>
            </div>
          </div>
        </v-row>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="!validAllData"
              v-if="currentChildren >= 0"
              @click="currentChildren = -1"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'childrenInfo',
  data () {
    return {
      loading: false,
      birthdayMenu: false,
      formatBirthday: null,
      currentChildren: -1
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return (this.creationDocument.attributes.children[this.currentChildren].first_name != null && !this.validateCyrillic(this.creationDocument.attributes.children[this.currentChildren].first_name) && !this.validateNumbers(this.creationDocument.attributes.children[this.currentChildren].first_name)) &&
        (this.creationDocument.attributes.children[this.currentChildren].last_name != null && !this.validateCyrillic(this.creationDocument.attributes.children[this.currentChildren].last_name) && !this.validateNumbers(this.creationDocument.attributes.children[this.currentChildren].last_name)) &&
        this.validBirthday()
    },

    userLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    creationDocument (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.children[this.currentChildren].birthday)
    },
    'creationDocument.attributes.children[this.currentChildren].birthday' (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.children[this.currentChildren].birthday)
    },
    currentChildren (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.children[this.currentChildren].birthday)
    }
  },
  mounted () {
    if (this.creationDocument) {
      this.$http.post(`/documents/${this.creatingDocumentState.document_id}/change_subprocess_step`, { data: { step_name: 'APK_4' } })
        .then(response => {
          console.log(response)
        })
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    handleDateSelection (date) {
      this.creationDocument.attributes.children[this.currentChildren].birthday = date
      this.formatBirthday = this.formatDate(date)
      this.birthdayMenu = false
    },

    validateCyrillic (value) {
      const cyrillicPattern = /[\u0400-\u04FF]/
      return cyrillicPattern.test(value)
    },

    validateNumbers (value) {
      const numberPattern = /\d/
      return numberPattern.test(value)
    },

    validBirthday () {
      if (this.formatBirthday) {
        const comparisonDate = new Date('1920', '01', '01')

        const dateParts = this.formatBirthday.split('/')
        const day = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        const year = parseInt(dateParts[2], 10)
        const inputDate = new Date(year, month, day)

        return inputDate > comparisonDate
      } else {
        return false
      }
    },

    submit () {
      this.loading = true
      const dataObject = {
        state: 'children_summary',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        children: this.creationDocument.attributes.children
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'children_summary'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .gradient-border-container {
    border-radius: 10px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 1px solid transparent !important;
  }
</style>
