<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('youAreDoingGreat') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/icons/thumb-up.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">{{ $t('youAreDoingGreatDescription') }}</div>
      </v-col>
    </div>
    <div class="container row mt-0">
      <v-col cols="6" lg="6" class="text-left">
        <button
          type="button"
          @click="undo"
          class="grey-text semibold-font"
        >{{ $t('undo') | uppercase }}</button>
      </v-col>
      <v-col cols="6" lg="6" class="text-right">
        <button
          :disabled="loading"
          @click="submit"
          type="submit"
          class="gradient-pink semibold-font"
        >{{ $t('next') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'youAreDoingGreat',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument'])
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'you_are_doing_great',
        client: {
          have_children: this.creationDocument.attributes.have_children
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'you_are_doing_great'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-agreement-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: justify;
    }
  }

  .info-text {
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: justify;
  }
</style>
