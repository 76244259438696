<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('maritalStatusIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('maritalStatusIntro') }}</p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div v-if="!displayDetails">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="8" sm="8">
            <h3 class="semibold-font">{{ $t('haveChildren') }}</h3>
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="row">
            <v-col cols="6" lg="6" class="text-left">
              <button :class="creationDocument.attributes.have_children == false ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.have_children = false">{{ $t('no') }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button :class="creationDocument.attributes.have_children == true ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.have_children = true">{{ $t('yes') }}</button>
            </v-col>
          </div>
          <div class="container row mt-0" v-if="creationDocument.attributes.have_children == true">
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                @click="displayDetails = true"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              </button>
            </v-col>
          </div>
          <div class="container row mt-0" v-if="creationDocument.attributes.have_children == false">
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                @click="submit('no_children')"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              </button>
            </v-col>
          </div>
        </v-row>
      </div>
      <div v-if="displayDetails">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="8" sm="8">
            <h3 class="semibold-font">{{ $t('howManyChildren') }}</h3>
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="row">
            <v-col cols="4" lg="4" class="text-left">
              <div class="kids-badge">1</div>
              <button :class="creationDocument.attributes.number_children == 1 ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.number_children = 1"><span><img class="kids-icon" src='@/assets/icons/one_kid.svg' /></span></button>
            </v-col>
            <v-col cols="4" lg="4" class="text-left">
              <div class="kids-badge">2</div>
              <button :class="creationDocument.attributes.number_children == 2 ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.number_children = 2"><span><img class="kids-icon" src='@/assets/icons/two_kids.svg' /></span></button>
            </v-col>
            <v-col cols="4" lg="4" class="text-left">
              <div class="kids-badge">3</div>
              <button :class="creationDocument.attributes.number_children == 3 ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.number_children = 3"><span><img class="kids-icon" src='@/assets/icons/three_kids.svg' /></span></button>
            </v-col>
            <v-col cols="12" lg="12" class="text-left">
              <div class="kids-badge">+</div>
              <button :class="creationDocument.attributes.number_children > 3 ? 'button-gradient-border' : 'button-border'">
                <div class="row">
                  <v-col cols="3" lg="3" class="text-left">
                    <span><img class="kids-icon" src='@/assets/icons/three_kids.svg' /></span>
                  </v-col>
                  <v-col cols="4" lg="4" class="text-left ma-0 ml-2 pa-0 pt-4">
                    <span>
                      <p class="mb-0">{{ $t('more') }}</p>
                      <p class="base-info-description mt-1">{{ $t('selectNumber') }}:</p>
                    </span>
                  </v-col>
                  <v-col cols="4" lg="4" class="text-left pt-0">
                    <v-container class="d-flex justify-center align-center pr-0" fill-height>
                      <div class="d-flex align-center pa-0">
                        <v-text-field
                          v-model="correctChildrenSize"
                          type="number"
                          class="counter-input"
                          hide-details
                          solo
                        ></v-text-field>
                        <div class="d-flex flex-column ml-2 number-children-arrows">
                          <v-btn
                            icon
                            :disabled="correctChildrenSize == 8"
                            class="arrow-btn up-arrow number-children-arrow"
                            @click="increment"
                          >
                            <v-icon>mdi-chevron-up</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            :disabled="correctChildrenSize == 4"
                            class="arrow-btn down-arrow number-children-arrow"
                            @click="decrement"
                          >
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-container>
                  </v-col>
                </div>
              </button>
            </v-col>
          </div>
        </v-row>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              @click="submit('children_info')"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'childrenInfo',
  data () {
    return {
      loading: false,
      displayDetails: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    correctChildrenSize () {
      return this.creationDocument.attributes.number_children < 4 ? 4 : this.creationDocument.attributes.number_children
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    increment () {
      this.creationDocument.attributes.number_children += 1
    },
    decrement () {
      this.creationDocument.attributes.number_children -= 1
    },
    submit (state) {
      this.loading = true
      const dataObject = {
        state: state,
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        children_size: this.creationDocument.attributes.number_children
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = state
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .kids-icon {
    width: 54px;
    height: 54px;
  }

  .kids-badge {
    position: absolute;
    margin-top: -10px;
    margin-left: -10px;
    height: 30px;
    width: 30px;
    background: linear-gradient(89.95deg, #F57921 0.05%, #D2116E 76.54%);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .number-children-arrows {
    position: absolute;
  }

  .up-arrow {
    margin-top: 10px;
    margin-left: 15px;
    height: 30px !important;
  }

  #app > div > div > div > div > main > div > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(3) > div > div:nth-child(2) > div > div.text-left.col-lg-12.col-12 > button > div > div:nth-child(3) > div > div > div.d-flex.flex-column.ml-2.number-children-arrows > button.arrow-btn.up-arrow.number-children-arrow.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--default > span > i {
    font-size: 30px !important;
  }
  #app > div > div > div > div > main > div > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(3) > div > div:nth-child(2) > div > div.text-left.col-lg-12.col-12 > button > div > div:nth-child(3) > div > div > div.d-flex.flex-column.ml-2.number-children-arrows > button.arrow-btn.down-arrow.number-children-arrow.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--default > span > i {
    font-size: 30px !important;
  }

  .down-arrow {
    margin-bottom: 10px;
    margin-left: 15px;
    margin-top: -10px;
    height: 30px !important;
  }
</style>
