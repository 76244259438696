<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('identifyNumberHeader1') }}<br>{{ $t('identifyNumberHeader2') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/icons/key-icon.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="pl-4 base-info-description">
        {{ $t('identifyNumberDescription1') }}
        <u>{{ countryName }}</u>
        {{ $t('identifyNumberDescription2') }}
      </p>
    </div>
    <div v-if="this.creationDocument.attributes.product == 'Niemcy'" class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">Identifikationsnummer</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.id_numer"
        ></v-text-field>
        <div class='error-notice' v-if="validIdNumer">
          {{ $t('ID_Numer_notice') }}
        </div>
      </v-col>
    </div>
    <div v-if="this.creationDocument.attributes.product == 'Holandia'" class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">BSN</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.bsn"
        ></v-text-field>
        <div class='error-notice' v-if="validBSN">
          {{ $t('BSN_notice') }}
        </div>
      </v-col>
    </div>
    <div v-if="this.creationDocument.attributes.product == 'Austria'" class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">Versicherungsnummer</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.versicherungsnummer"
        ></v-text-field>
        <!-- <div class='error-notice' v-if="validVersicherungsnummer">
          {{ $t('Versicherungsnummer_notice') }}
        </div> -->
      </v-col>
    </div>
    <div v-if="this.creationDocument.attributes.product == 'Belgia'" class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">Numéro National/National Number</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.nn"
        ></v-text-field>
        <div class='error-notice' v-if="validNN">
          {{ $t('NN_notice') }}
        </div>
      </v-col>
    </div>
    <div v-if="this.creationDocument.attributes.product == 'Wielka Brytania'" class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">National Insurance Number (NINo)</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.nino"
        ></v-text-field>
        <div class='error-notice' v-if="validNINO">
          {{ $t('NINO_notice') }}
        </div>
      </v-col>
    </div>
    <div v-if="this.creationDocument.attributes.product == 'Irlandia'" class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">PPS</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.pps"
        ></v-text-field>
        <div class='error-notice' v-if="validPPS">
          {{ $t('PPS_notice') }}
        </div>
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0 mt-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <v-row class="mt-6">
          <v-col cols="2" class="mt-2">
            <span><img src='@/assets/icons/i-icon.svg' /></span>
          </v-col>
          <v-col cols="10" class="info-text">
            <span >{{ $t('identifyNumberInfo') }}</span>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <div class="container row mt-0">
      <v-col cols="6" lg="6" class="text-left">
        <button
          type="button"
          @click="undo"
          class="grey-text semibold-font"
        >{{ $t('undo') | uppercase }}</button>
      </v-col>
      <v-col cols="6" lg="6" class="text-right">
        <button
          :disabled="validAllData || loading"
          @click="submit"
          type="submit"
          class="gradient-pink semibold-font"
        >{{ $t('next') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      if (this.creationDocument && this.creationDocument.attributes.product === 'Niemcy') {
        return this.validIdNumer
      } else if (this.creationDocument && this.creationDocument.attributes.product === 'Holandia') {
        return this.validBSN
      // } else if (this.creationDocument && this.creationDocument.attributes.product === 'Austria') {
      //   return this.validVersicherungsnummer
      } else if (this.creationDocument && this.creationDocument.attributes.product === 'Belgia') {
        return this.validNN
      } else if (this.creationDocument && this.creationDocument.attributes.product === 'Wielka Brytania') {
        return this.validNINO
      }
      // } else if (this.creationDocument && this.creationDocument.attributes.product === 'Irlandia') {
      //   return this.validPPS
      // }

      return false
    },

    validIdNumer () {
      if (this.creationDocument && this.creationDocument.attributes.id_numer) {
        return !(this.creationDocument.attributes.id_numer.length === 11) || !this.checkIfOnlyNumbers(this.creationDocument.attributes.id_numer)
      }
      return false
    },

    validBSN () {
      if (this.creationDocument && this.creationDocument.attributes.bsn) {
        return !(this.creationDocument.attributes.bsn.length === 9) || !this.checkIfOnlyNumbers(this.creationDocument.attributes.bsn)
      }
      return false
    },

    validVersicherungsnummer () {
      const convertedBirthday = moment(this.creationDocument.attributes.birthday).format('DDMMYY')
      if (this.creationDocument && this.creationDocument.attributes.versicherungsnummer && this.creationDocument.attributes.versicherungsnummer.includes(convertedBirthday)) {
        return !(this.creationDocument.attributes.versicherungsnummer.length === 10) || !this.checkIfOnlyNumbers(this.creationDocument.attributes.versicherungsnummer)
      } else {
        return false
      }
    },

    validNN () {
      if (this.creationDocument && this.creationDocument.attributes.nn) {
        return !(this.creationDocument.attributes.nn.length === 11) || !this.checkIfOnlyNumbers(this.creationDocument.attributes.nn)
      }
      return false
    },

    validNINO () {
      if (this.creationDocument && this.creationDocument.attributes.nino) {
        const regex = /^\s*([a-zA-Z]{2}\s[0-9]{2}\s[0-9]{2}\s[0-9]{2}\s[a-zA-Z]|[0-9]{2}\s[a-zA-Z][0-9]\s[0-9]{2}\s[0-9]{2}|UNKNOWN)?\s*$/
        return !regex.test(this.creationDocument.attributes.nino)
      }
      return false
    },

    validPPS () {
      if (this.creationDocument && this.creationDocument.attributes.pps) {
        const regex = /^\s*([0-9]{8}[a-zA-z]{1}|[0-9]{7}[a-zA-z]{2}|[a-zA-z]{2}\s[0-9]{2}\s[0-9]{2}\s[0-9]{2}|[0-9]{2}\s[a-zA-z]{1}[0-9]{1}\s[0-9]{2}\s[0-9]{2}|[0-9]{7}[a-zA-z]{1}|UNKNOWN)?\s*$/
        return !regex.test(this.creationDocument.attributes.pps)
      }
      return false
    },

    countryName () {
      if (this.creationDocument && this.creationDocument.attributes.product) {
        return this.$t('adjectiveCountries.' + this.creationDocument.attributes.product)
      }
      return ''
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    checkIfOnlyNumbers (value) {
      const regex = /^[0-9]*$/
      return regex.test(value)
    },

    submit () {
      this.loading = true
      const correctPPS = this.creationDocument.attributes.product === 'Irlandia' && this.creationDocument.attributes.pps ? this.creationDocument.attributes.pps : 'UNKNOWN'
      const dataObject = {
        state: 'identify_number',
        client: {
          id_numer: this.creationDocument.attributes.id_numer,
          bsn: this.creationDocument.attributes.bsn,
          versicherungsnummer: this.creationDocument.attributes.versicherungsnummer,
          nn: this.creationDocument.attributes.nn,
          nino: this.creationDocument.attributes.nino,
          pps: correctPPS
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'identify_number'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-agreement-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: justify;
    }
  }

  .info-text {
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: justify;
  }
</style>
