<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('baseInfoIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('baseInfoIntro') }}<br><br><u>{{ $t('baseInfoIntro2') }}</u></p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <form @submit.prevent="submit">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('firstName') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              required
              autofocus
              v-model="creationDocument.attributes.first_name"
            ></v-text-field>
            <div class='error-notice' v-if="creationDocument.attributes.first_name && validateCyrillic(creationDocument.attributes.first_name)">
              {{ $t('dont_use_cyrillic') }}
            </div>
            <div class='error-notice' v-if="creationDocument.attributes.first_name && (validateNumbers(creationDocument.attributes.first_name) || validateSpecialCharacters(creationDocument.attributes.first_name) || validateSpaces(creationDocument.attributes.first_name))">
              {{ $t('dont_use_numbers') }}
            </div>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('lastName') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              required
              autofocus
              v-model="creationDocument.attributes.last_name"
            >
            </v-text-field>
            <div class='error-notice' v-if="creationDocument.attributes.last_name && validateCyrillic(creationDocument.attributes.last_name)">
              {{ $t('dont_use_cyrillic') }}
            </div>
            <div class='error-notice' v-if="creationDocument.attributes.last_name && (validateNumbers(creationDocument.attributes.last_name) || validateSpecialCharacters(creationDocument.attributes.last_name) || validateSpaces(creationDocument.attributes.last_name))">
              {{ $t('dont_use_numbers') }}
            </div>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="question-marks">
              <div class="base-info-description mb-1 question-mark inline-block">{{ $t('phone') }}</div>
              <div class="question-mark inline-block ml-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <img v-on="on" src="@/assets/icons/question_tooltip.svg">
                  </template>
                  <span>{{ $t('phoneTooltip') }}</span>
                </v-tooltip>
              </div>
            </div>
            <vue-phone-number-input
              class="base-form-input"
              :no-validator-state="true"
              :no-example="true"
              :translations="translations"
              :preferred-countries="['PL', 'RO', 'BG']"
              outlined
              required
              @update="phone2Updated"
              v-model="creationDocument.attributes.phone" />
            <div class='error-notice' v-if="creationDocument.attributes.phone && !validPhone">
              {{ $t('wrongPhoneRegistration') }}
            </div>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="question-marks">
              <div class="base-info-description mb-1 question-mark inline-block">{{ $t('email') }}</div>
              <div class="question-mark inline-block ml-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <img v-on="on" src="@/assets/icons/question_tooltip.svg">
                  </template>
                  <span>{{ $t('emailTooltip') }}</span>
                </v-tooltip>
              </div>
            </div>
            <v-text-field
              class="base-form-input"
              outlined
              required
              hide-details="true"
              v-model="creationDocument.attributes.email"
            ></v-text-field>
            <div class='error-notice' v-if="creationDocument.attributes.email && !validEmail">
              {{ $t('wrongEmailRegistration') }}
            </div>
            <div class='error-notice' v-if="creationDocument.attributes.email && (validateCyrillic(creationDocument.attributes.email) || validateSpaces(creationDocument.attributes.email))">
              {{ $t('dont_use_cyrillic') }}
            </div>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('birthday') }}</div>
            <v-menu
              ref="birthdayMenu"
              v-model="birthdayMenu"
              class="birthday-menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatBirthday"
                  prepend-inner-icon="mdi-calendar"
                  hint="DD/MM/YYYY"
                  persistent-hint
                  class="base-form-input"
                  hide-details="true"
                  outlined
                  required
                  readonly
                  @blur="creationDocument.attributes.birthday = parseDate(formatBirthday)"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="creationDocument.attributes.birthday"
                no-title
                :locale="userLocale"
                @input="handleDateSelection"
              ></v-date-picker>
            </v-menu>
            <div class='error-notice' v-if="formatBirthday && !validBirthday()">
              {{ $t('wrong_birthday') }}
            </div>
          </v-col>
          <v-col cols="12" lg="12" class="text-right">
            <button
              :disabled="!validAllData || loading"
              type="submit"
              class="gradient-pink semibold-font"
            >{{ $t('confirm') | uppercase }}
            <v-progress-circular
              v-if="loading"
              :size="15"
              color="grey"
              indeterminate
            ></v-progress-circular>
            </button>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  data () {
    return {
      birthdayMenu: false,
      formatBirthday: null,
      temporaryPhoneNumber: null,
      phoneIsValid: false,
      loading: false
    }
  },
  mounted () {
    if (this.creationDocument) {
      this.loading = true
      this.$http.post(`/documents/${this.creationDocument.id}/change_subprocess_step`, { data: { step_name: 'APK_3' } })
        .then(response => {
          console.log(response)
        })
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return (this.validFirstName) &&
        (this.validLastName) &&
        (this.validPhone) &&
        (this.validEmail && !this.validateSpaces(this.creationDocument.attributes.email)) &&
        this.validBirthday()
    },

    translations () {
      return {
        countrySelectorLabel: this.$t('countrySelectorLabel'),
        countrySelectorError: this.$t('countrySelectorError'),
        phoneNumberLabel: this.$t('phoneNumberLabel'),
        example: this.$t('example')
      }
    },

    userLocale () {
      return this.$i18n.locale
    },

    validFirstName () {
      return this.creationDocument && this.creationDocument.attributes.first_name != null && !this.validateCyrillic(this.creationDocument.attributes.first_name) && !this.validateNumbers(this.creationDocument.attributes.first_name) && !this.validateSpecialCharacters(this.creationDocument.attributes.first_name) && !this.validateSpaces(this.creationDocument.attributes.first_name)
    },

    validLastName () {
      return this.creationDocument && this.creationDocument.attributes.last_name != null && !this.validateCyrillic(this.creationDocument.attributes.last_name) && !this.validateNumbers(this.creationDocument.attributes.last_name) && !this.validateSpecialCharacters(this.creationDocument.attributes.last_name) && !this.validateSpaces(this.creationDocument.attributes.last_name)
    },

    validEmail () {
      if (this.creationDocument && this.creationDocument.attributes.email) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return !!this.creationDocument.attributes.email.match(emailRegex)
      } else {
        return false
      }
    },

    validPhone () {
      if (this.creationDocument && this.creationDocument.attributes.phone) {
        const phoneRegex = /^\d{9}$/
        return !!this.creationDocument.attributes.phone.replace(/\s+/g, '').match(phoneRegex)
      } else {
        return false
      }
    }
  },
  watch: {
    creationDocument (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.birthday)
    },
    'creationDocument.attributes.birthday' (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.birthday)
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    phone2Updated (data) {
      this.temporaryPhoneNumber = data.formattedNumber
    },

    validateSpecialCharacters (value) {
      const specialCharactersPattern = /[!@#$%^&*()_+={}|\\:;<>,.?/~]/
      return specialCharactersPattern.test(value)
    },

    validateSpaces (value) {
      const spacesPattern = /\s/
      return spacesPattern.test(value)
    },

    validateCyrillic (value) {
      const cyrillicPattern = /[\u0400-\u04FF]/
      return cyrillicPattern.test(value)
    },

    validateNumbers (value) {
      const numberPattern = /\d/
      return numberPattern.test(value)
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    handleDateSelection (date) {
      this.creationDocument.attributes.birthday = date
      this.formatBirthday = this.formatDate(date)
      this.birthdayMenu = false
    },

    validBirthday () {
      if (this.formatBirthday) {
        const comparisonDate = new Date('1920', '01', '01')

        const today = new Date()
        const minAgeDate = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate())

        const dateParts = this.formatBirthday.split('/')
        const day = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        const year = parseInt(dateParts[2], 10)
        const inputDate = new Date(year, month, day)

        return inputDate > comparisonDate && inputDate <= minAgeDate
      } else {
        return false
      }
    },

    submit () {
      this.loading = true
      const dataObject = {
        state: 'base_info1',
        client: {
          first_name: this.creationDocument.attributes.first_name,
          last_name: this.creationDocument.attributes.last_name,
          phone: this.temporaryPhoneNumber,
          email: this.creationDocument.attributes.email,
          birthday: this.creationDocument.attributes.birthday
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'base_info1'
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .v-date-picker-table {
    tbody tr {
      height: 30px !important;
    }
  }

  .input-tel__input {
    background-color: rgba(180, 180, 180, 0.1) !important;
    border-radius: 10px !important;
    border: none !important;
    padding: 0 12px !important;
    min-height: 56px !important;
  }

  .input-country-selector {
    min-height: 56px !important;
  }

  .country-selector__country-flag {
    margin-top: 7px !important;
  }

  .country-selector__input {
    min-height: 56px !important;
  }

  .vue-phone-number-input .select-country-container {
    flex: 0 0 110px !important;
    width: 110px !important;
    min-width: 110px !important;
  }
</style>
