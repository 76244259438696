<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('startNewSettlement') }}<br>{{ $t('startNewSettlement2') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/globe.svg' />
      </v-col>
    </div>
    <div class="container mt-0">
      <p cols="12 text-left" class="pl-4">{{ $t('startNewSettlementIntro') }}<br>{{ $t('startNewSettlementIntro2') }}</p>
      <v-col cols="12">
        <v-select
          v-model="object.attributes.country"
          :items="countries"
          :label="$t('selectCountry')"
          class="outlined__input gradient-border"
          outlined
        >
          <template v-slot:selection="{ item } ">
            <div>
              {{ item.text }}
              <img class="countryFlag text-right" :src="getItemIcon(item)" />
            </div>
          </template>
          <template v-slot:item="{ item } ">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
                <img class="countryFlag text-right" :src="getItemIcon(item)" />
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-select
          :disabled="!object.attributes.country"
          v-model="object.attributes.year"
          :items="years"
          :label="$t('selectYear')"
          class="outlined__input gradient-border"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="12" lg="12" class="text-left mt-2">
        <p v-if="object.attributes.year && notSelectedYears.length > 0 && object.attributes.country === 'Wielka Brytania'" class="base-info-description gradient-pink semibold-font">{{ $t('uk_additional_years_info') }}</p>
        <button
          v-if="object.attributes.year && notSelectedYears.length > 0 && object.attributes.country !== 'Wielka Brytania'"
          class="gradient-pink semibold-font"
          @click="displayAnotherYears = !displayAnotherYears"
          ><button class="mobile-menu-item plus-gradient-icon mr-3"></button>{{ $t('addNextYear') }}
        </button>
      </v-col>
      <v-col cols="12" lg="12" class="text-left mt-2" v-if="displayAnotherYears">
        <div class="row">
          <v-col cols="6" lg="6" class="text-left" v-for="year in notSelectedYears" :key="year">
            <button class="semibold-font" :class="object.attributes.anotherYears.includes(year) ? 'activeAnotherYear' : 'button-gradient-border'" @click="toggleAnotherYear(year)">{{ year }}</button>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" lg="12" class="text-right mt-4">
        <button
          :disabled="loading || !object.attributes.country || !object.attributes.year"
          @click="createNewDocument()"
          class="gradient-pink semibold-font"
        >{{ $t('next') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'newDocument',
  data () {
    return {
      loading: false,
      object: {
        attributes: {
          country: null,
          year: null,
          anotherYears: []
        }
      },
      displayAnotherYears: false,
      countries: [
        { value: 'Niemcy', text: this.$i18n.t('Germany') },
        { value: 'Holandia', text: this.$i18n.t('Netherlands') },
        { value: 'Wielka Brytania', text: this.$i18n.t('United_Kingdom') },
        { value: 'Austria', text: this.$i18n.t('Austria') },
        { value: 'Belgia', text: this.$i18n.t('Belgium') },
        { value: 'Irlandia', text: this.$i18n.t('Ireland') }
      ]
    }
  },
  computed: {
    ...mapGetters(['currentClient']),
    ...mapGetters(['currentDocuments']),
    ...mapGetters(['loadingInfo']),
    ...mapGetters(['creatingDocumentState']),

    notSelectedYears () {
      return this.years.filter(year => year !== this.object.attributes.year)
    },
    years () {
      const filteredYears = []
      const currentYear = new Date().getFullYear()
      if (this.object.attributes.country === 'Niemcy') {
        for (let i = 0; i < 5; i++) {
          if (this.currentDocuments.find(doc => doc.year === (currentYear - i).toString() && doc.product === 'Niemcy')) {
            continue
          }
          filteredYears.push(currentYear - i)
        }
      } else if (this.object.attributes.country === 'Holandia') {
        for (let i = 0; i < 6; i++) {
          if (this.currentDocuments.find(doc => doc.year === (currentYear - i).toString() && doc.product === 'Holandia')) {
            continue
          }
          filteredYears.push(currentYear - i)
        }
      } else if (this.object.attributes.country === 'Wielka Brytania') {
        for (let i = 0; i < 4; i++) {
          const startYear = currentYear - i
          const endYear = startYear + 1
          const yearPairText = `${startYear}/${endYear}`
          if (this.currentDocuments.find(doc => doc.year === yearPairText && doc.product === 'Wielka Brytania')) {
            continue
          }
          filteredYears.push(yearPairText)
        }
      } else if (this.object.attributes.country === 'Austria') {
        for (let i = 0; i < 6; i++) {
          if (this.currentDocuments.find(doc => doc.year === (currentYear - i).toString() && doc.product === 'Austria')) {
            continue
          }
          filteredYears.push(currentYear - i)
        }
      } else if (this.object.attributes.country === 'Belgia') {
        for (let i = 0; i < 4; i++) {
          if (this.currentDocuments.find(doc => doc.year === (currentYear - i).toString() && doc.product === 'Belgia')) {
            continue
          }
          filteredYears.push(currentYear - i)
        }
      } else if (this.object.attributes.country === 'Irlandia') {
        for (let i = 0; i < 5; i++) {
          if (this.currentDocuments.find(doc => doc.year === (currentYear - i).toString() && doc.product === 'Irlandia')) {
            continue
          }
          filteredYears.push(currentYear - i)
        }
      }

      return filteredYears.sort((a, b) => b - a)
    },
    correctCompanyName () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'Euro-Tax.ro'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'Euro-Tax.bg'
      } else if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'CUF Sp. z o.o.'
      } else {
        return 'Euro-Tax.pl'
      }
    }
  },
  watch: {
    'object.attributes.country' () {
      this.object.attributes.year = null
      this.displayAnotherYears = false
    }
  },
  methods: {
    createNewDocument () {
      this.loading = true
      const dataObject = {
        state: 'create_document',
        country: this.object.attributes.country,
        year: this.object.attributes.year,
        anotherYears: this.object.attributes.anotherYears,
        company: this.correctCompanyName
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          try {
            this.$store.dispatch('getCreationDocument', response.data.data.id)
            this.creatingDocumentState.document_id = response.data.data.id
            this.creatingDocumentState.state = 'create_document'
            this.creatingDocumentState.year = this.object.attributes.year
            this.creatingDocumentState.country = this.object.attributes.country
            this.loading = false
          } catch (error) {
            console.error('Error in then block:', error)
            this.loading = false
          }
        })
        .catch((_error) => {
          console.error('Error in catch block:', _error)
          this.loading = false
        })
    },
    toggleAnotherYear (year) {
      if (this.object.attributes.anotherYears.includes(year)) {
        this.object.attributes.anotherYears = this.object.attributes.anotherYears.filter(y => y !== year)
      } else {
        this.object.attributes.anotherYears.push(year)
      }
    },
    getItemIcon (item) {
      if (item.value === 'Niemcy') {
        return require('@/assets/DE.svg')
      } else if (item.value === 'Holandia') {
        return require('@/assets/NL.svg')
      } else if (item.value === 'Wielka Brytania') {
        return require('@/assets/UK.svg')
      } else if (item.value === 'Austria') {
        return require('@/assets/AUS.svg')
      } else if (item.value === 'Belgia') {
        return require('@/assets/BEL.svg')
      } else if (item.value === 'Irlandia') {
        return require('@/assets/IRE.svg')
      }
    }
  }
}
</script>

<style lang="scss">

  .startNewSettlementIntro {
    padding-left: 12px !important;
  }

  .countryFlag {
    width: 34px;
    height: 20px;
    margin-right: 10px;
    float: right;
  }

  .v-select__selections {
    display: inline !important;
    input {
      display: none !important;
    }
  }

  .activeAnotherYear {
    background: linear-gradient(270deg, #D20F6E 0%, #F57921 101.54%) !important;
    color: white !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    padding: 12px 18px 12px 18px;
    width: 100%;
  }

  .button-gradient-border {
    border-radius: 10px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 1px solid transparent !important;
    padding: 12px 18px 12px 18px;
    width: 100%;
  }

  .gradient-border {
    .v-input__slot {
      border-radius: 10px !important;
      opacity: 1;
      background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F57921, #D20F6E) border-box !important;
      border: 1px solid transparent !important;
    }

    fieldset {
      border: none !important;
    }

    .v-label--active {
      display: none !important;
    }
  }

  .v-input--is-disabled {
    .v-input__slot {
      border-radius: 10px !important;
      opacity: 1;
      background: rgba(180, 180, 180, 0.1) !important;
      border: 1px solid transparent !important;
    }

    fieldset {
      background-color: rgba(180, 180, 180, 0.1);
    }
  }

  .gradient-pink:disabled {
    background: linear-gradient(270deg, rgba(180, 180, 180, 1) 100%, rgba(180, 180, 180, 1) 101.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .plus-gradient-icon {
    background: transparent url('./../../assets/icons/plus-gradient.png') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }
</style>
