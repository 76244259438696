<template>
  <div>
    <div class="container row text-center mt-0 pt-0 ml-1 pr-10">
      <v-col cols="12 text-center mt-0 pt-0 mr-0 pr-0">
        <h2 class="semibold-font mb-1 mt-3">{{ $t('order_accepted_header') }}</h2>
      </v-col>
      <v-col cols="12 text-center">
        <img class="" src='@/assets/orderAccepted.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pr-10">
      <p cols="12" class="pl-4 base-info-description">{{ $t('regular_client_info') }}</p>
    </div>
    <div class="container row mt-0">
      <v-col cols="6" lg="6" class="text-left">
      </v-col>
      <v-col cols="6" lg="6" class="text-right">
        <button
          @click="submit()"
          class="gradient-pink semibold-font"
        >{{ $t('next') | uppercase }}
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'orderAccepted',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return true
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'order_accepted' })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'order_accepted'
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-regular-client-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      line-height: 24px !important;
      font-weight: 600 !important;
      text-align: left !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }

  .promotion-more-info {
    margin-top: -30px;
  }

  .agreement-logo {
    width: 80px;
    height: 80px;
  }

   .link-container {
      display: flex;
      font-size: 18px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #e44d26;
      text-decoration: underline !important;
  }
</style>
