<template>
  <div class="container newDocument__container mt-12">
    <div class="text-center sticky" style="position: sticky; top: 50%">
      <v-progress-circular
        v-if="loadingInfo || loading"
        :size="80"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!loadingInfo && !loading">
      <div class="container text-center mt-2">
        <img class="logo" :src="logoSvg" />
      </div>
      <createState v-if="newDocumentState" />
      <component v-else :is="getCurrentComponent()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import createState from '@/components/newDocument/create.vue'
import baseInfo from '@/components/newDocument/baseInfo.vue'
import baseInfo2 from '@/components/newDocument/baseInfo2.vue'
import baseInfoAdditionalPhone from '@/components/newDocument/baseInfoAdditionalPhone.vue'
import baseInfoAddress from '@/components/newDocument/baseInfoAddress.vue'
import acceptAgreement from '@/components/newDocument/acceptAgreement.vue'
import regularClient from '@/components/newDocument/regularClient.vue'
import orderAccepted from '@/components/newDocument/orderAccepted.vue'
import identifyNumber from '@/components/newDocument/identifyNumber.vue'
import maritalStatus from '@/components/newDocument/maritalStatus.vue'
import childrenInfo from '@/components/newDocument/childrenInfo.vue'
import childrenDetails from '@/components/newDocument/childrenDetails.vue'
import childrenSummary from '@/components/newDocument/childrenSummary.vue'
import originCountryIncome from '@/components/newDocument/originCountryIncome.vue'
import globalIncome from '@/components/newDocument/globalIncome.vue'
import polishPit from '@/components/newDocument/polishPit.vue'
import eog from '@/components/newDocument/eog.vue'
import pesel from '@/components/newDocument/pesel.vue'
import employmentData from '@/components/newDocument/employmentData.vue'
import youAreDoingGreat from '@/components/newDocument/youAreDoingGreat.vue'
import kindergeld from '@/components/newDocument/kindergeld.vue'
import kinderbijslag from '@/components/newDocument/kinderbijslag.vue'
import zorgtoeslag from '@/components/newDocument/zorgtoeslag.vue'
import zorgtoeslagIncome from '@/components/newDocument/zorgtoeslagIncome.vue'
import thankYou from '@/components/newDocument/thankYou.vue'
import jotform from '@/components/newDocument/jotform.vue'
export default {
  name: 'newDocument',
  data () {
    return {
      loading: false
    }
  },
  components: {
    createState,
    baseInfo,
    baseInfo2,
    baseInfoAdditionalPhone,
    baseInfoAddress,
    acceptAgreement,
    regularClient,
    orderAccepted,
    identifyNumber,
    maritalStatus,
    childrenInfo,
    childrenDetails,
    childrenSummary,
    originCountryIncome,
    globalIncome,
    polishPit,
    eog,
    pesel,
    employmentData,
    youAreDoingGreat,
    kindergeld,
    kinderbijslag,
    zorgtoeslag,
    zorgtoeslagIncome,
    thankYou,
    jotform
  },
  created () {
    const documentId = this.$route.params.documentId
    this.$store.dispatch('getInfo', documentId)
  },
  watch: {
    creatingDocumentState: {
      handler (newState) {
        const stateFromQuery = this.$route.query.state
        if (newState && stateFromQuery) {
          this.$set(newState, 'state', stateFromQuery)
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (!this.$route.params.documentId) {
      this.loading = true
      this.$http.post('/documents/', {
        country: 'UNKNOWN',
        year: '1111',
        company: this.correctCompanyName
      })
        .then(response => {
          try {
            this.$store.dispatch('getCreationDocument', response.data.data.id)
            this.creatingDocumentState.document_id = response.data.data.id
            this.creatingDocumentState.state = 'empty_document'
            this.$router.push(`/documents/${response.data.data.id}/edit?state=empty_document`)
            this.loading = false
          } catch (error) {
            console.error('Error in then block:', error)
            this.loading = false
          }
        })
        .catch((_error) => {
          console.error('Error in catch block:', _error)
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters(['currentDocuments']),
    ...mapGetters(['loadingInfo']),
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    correctCompanyName () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'Euro-Tax.ro'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'Euro-Tax.bg'
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Euro-Tax.pl'
      } else {
        return 'CUF Sp. z o.o.'
      }
    },

    kgKbYears () {
      const currentYear = new Date().getFullYear()
      return [currentYear.toString(), (currentYear - 1).toString()]
    },

    newDocumentState () {
      if (!this.$route.params.documentId) {
        return true
      } else if (this.creatingDocumentState.state === 'empty_document') {
        return true
      } else if (this.creatingDocumentState.state === 'jotform_data' || this.creatingDocumentState.state === 'thank_you') {
        return true
      } else if (this.getCurrentComponent() == null) {
        return true
      } else if (this.creatingDocumentState.state === 'total_income_above_25k' && this.creatingDocumentState.product === 'Holandia' && this.creatingDocumentState.have_children === true) {
        return false
      } else if (this.creatingDocumentState.state === 'worked_in_de' && this.creatingDocumentState.product === 'Niemcy' && this.creatingDocumentState.have_children === true) {
        return false
      } else if (this.creatingDocumentState.state === 'you_are_doing_great' && ((this.creatingDocumentState.product === 'Niemcy' && this.creatingDocumentState.have_children === false) || (this.creatingDocumentState.product === 'Niemcy' && this.creatingDocumentState.have_children === true && !this.kgKbYears.includes(this.creatingDocumentState.year)))) {
        return false
      } else if (this.creatingDocumentState.state === 'you_are_doing_great' && ((this.creatingDocumentState.product === 'Holandia' && this.creatingDocumentState.have_children === false) || (this.creatingDocumentState.product === 'Holandia' && this.creatingDocumentState.have_children === true && !this.kgKbYears.includes(this.creatingDocumentState.year)))) {
        return false
      } else if (this.creatingDocumentState.state === 'you_are_doing_great' && this.creatingDocumentState.product !== 'Niemcy' && this.creatingDocumentState.product !== 'Holandia') {
        return false
      } else if (this.creatingDocumentState == null || this.creatingDocumentState.state == null) {
        return true
      } else {
        return false
      }
    },

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    },

    companyLocation () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'RO'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'BG'
      } else {
        return 'PL'
      }
    }
  },
  methods: {
    getCurrentComponent () {
      const state = this.creatingDocumentState.state
      const product = this.creationDocument ? this.creationDocument.attributes.document_country : this.creatingDocumentState.product
      const haveChildren = this.creationDocument ? this.creationDocument.attributes.have_children : this.creatingDocumentState.have_children
      const isB0 = this.creationDocument ? this.creationDocument.attributes.is_b0 : this.creatingDocumentState.is_b0

      if (state === 'create_document' && product === 'Holandia' && this.kgKbYears.includes(this.creatingDocumentState.year.toString())) {
        return 'zorgtoeslag'
      } else if (state === 'need_zorgtoeslag' && product === 'Holandia' && this.kgKbYears.includes(this.creatingDocumentState.year.toString())) {
        return 'zorgtoeslagIncome'
      } else if (state === 'create_document' || state === 'total_income_above_25k') {
        return 'baseInfo'
      } else if (state === 'base_info1') {
        return 'baseInfo2'
      } else if (state === 'base_info2') {
        return 'baseInfoAdditionalPhone'
      } else if (state === 'base_info_additional_phone') {
        return 'baseInfoAddress'
      } else if (state === 'base_info_address') {
        return 'identifyNumber'
      } else if (state === 'identify_number') {
        return 'maritalStatus'
      } else if (state === 'marital_status_single') {
        return 'originCountryIncome'
      } else if (state && state.includes('martial_status_')) {
        return 'childrenInfo'
      } else if (state === 'children_info') {
        return 'childrenDetails'
      } else if (state === 'children_details') {
        return 'childrenSummary'
      } else if (['children_summary', 'no_children'].includes(state)) {
        if (isB0) {
          return 'originCountryIncome'
        } else {
          return 'acceptAgreement'
        }
      } else if (state === 'accept_agreement') {
        if (isB0) {
          return 'originCountryIncome'
        } else {
          return 'regularClient'
        }
      } else if (state === 'regular_client') {
        if (isB0) {
          return 'originCountryIncome'
        } else {
          return 'orderAccepted'
        }
      } else if (state === 'order_accepted') {
        return 'originCountryIncome'
      } else if (state === 'origin_country_income') {
        return 'globalIncome'
      } else if (state === 'global_income_amounts' && this.companyLocation === 'PL' && !['Irlandia', 'Wielka Brytania'].includes(product)) {
        return 'polishPit'
      } else if (state === 'who_perform_client_pit' && this.companyLocation === 'PL') {
        return 'eog'
      } else if (state === 'who_obtains_certificate' && this.companyLocation === 'PL') {
        return 'pesel'
      } else if ((state === 'pesel' && this.companyLocation === 'PL' && !['Irlandia', 'Wielka Brytania'].includes(product)) || (state === 'global_income_amounts' && this.companyLocation !== 'PL')) {
        return 'employmentData'
      } else if ((state === 'employment_data_who_provides') || (state === 'pesel' && this.companyLocation === 'PL' && ['Irlandia', 'Wielka Brytania'].includes(product)) || (state === 'global_income_amounts' && this.companyLocation === 'PL' && ['Irlandia', 'Wielka Brytania'].includes(product))) {
        return 'youAreDoingGreat'
      } else if (state === 'you_are_doing_great' && product === 'Niemcy' && this.kgKbYears.includes(this.creatingDocumentState.year.toString()) && haveChildren) {
        return 'kindergeld'
      } else if (state === 'you_are_doing_great' && product === 'Holandia' && this.kgKbYears.includes(this.creatingDocumentState.year.toString()) && haveChildren) {
        return 'kinderbijslag'
      } else {
        return 'thankYou'
      }
      // } else if (this.creatingDocumentState.jotform_id || (this.creationDocument && this.creationDocument.attributes.jotform_id)) {
      //   return 'jotform'
      // }
    }
  }
}
</script>

<style lang="scss">
  .newDocument__container {
    min-height: 500px;
    width: 90%;
    border-radius: 20px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 2px solid transparent !important;
  }

  .startNewSettlementIntro {
    padding-left: 12px !important;
  }

  .countryFlag {
    width: 34px;
    height: 20px;
    margin-right: 10px;
    float: right;
  }

  .v-select__selections {
    display: inline !important;
    input {
      display: none !important;
    }
  }

  .activeAnotherYear {
    background: linear-gradient(270deg, #D20F6E 0%, #F57921 101.54%) !important;
    color: white !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    padding: 12px 18px 12px 18px;
    width: 100%;
  }

  .button-border {
    border-radius: 10px !important;
    opacity: 1;
    border: 1px solid transparent !important;
    padding: 12px 18px 12px 18px;
    width: 100%;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.12);
  }

  .button-gradient-border {
    border-radius: 10px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 1px solid transparent !important;
    padding: 12px 18px 12px 18px;
    width: 100%;
  }

  .gradient-border {
    .v-input__slot {
      border-radius: 10px !important;
      opacity: 1;
      background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F57921, #D20F6E) border-box !important;
      border: 1px solid transparent !important;
    }

    fieldset {
      border: none !important;
    }

    .v-label--active {
      display: none !important;
    }
  }

  .v-input--is-disabled {
    .v-input__slot {
      border-radius: 10px !important;
      opacity: 1;
      background: rgba(180, 180, 180, 0.1) !important;
      border: 1px solid transparent !important;
    }

    fieldset {
      background-color: rgba(180, 180, 180, 0.1);
    }
  }

  .gradient-pink:disabled {
    background: linear-gradient(270deg, rgba(180, 180, 180, 1) 100%, rgba(180, 180, 180, 1) 101.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .plus-gradient-icon {
    background: transparent url('./../assets/icons/plus-gradient.png') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }
</style>
