<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('greatMessage') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/icons/baby-stroller.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="base-info-description">{{ $t('kindergeldDescription') }}</p>
    </div>
    <div class="container mt-0 mb-0">
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('stillWorkInDe') }}</h3>
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0 pr-0" cols="12" sm="12">
                <v-radio-group v-model="workedInDe">
                  <v-radio :label="$t('wokingInDe')" value="working_in_de"></v-radio>
                  <v-radio :label="$t('workedInDe6monthsAgo')" value="worked_in_de_6_months_ago"></v-radio>
                  <v-radio :label="$t('workedInDe')" value="worked_in_de"></v-radio>
                  <v-radio :label="$t('notInterested')" value="not_interested"></v-radio>
                </v-radio-group>
              </v-col>
            </div>
          </div>
        </v-row>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="workedInDe == null || loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'eog',
  data () {
    return {
      loading: false,
      workedInDe: null
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument'])
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'worked_in_de',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        workedInDe: this.workedInDe
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'worked_in_de'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .grey-exclamation-mark-icon {
    background: transparent url('./../../assets/icons/grey-exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
</style>
