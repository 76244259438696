<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('peselHeader1') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/icons/key-icon.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <div class="base-info-description mb-1">PESEL</div>
        <v-text-field
          class="base-form-input"
          hide-details="true"
          outlined
          autofocus
          v-model="creationDocument.attributes.pesel"
        ></v-text-field>
        <div class='error-notice' v-if="validPesel">
          {{ $t('PESEL_notice') }}
        </div>
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0 mt-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <v-row class="mt-6">
          <v-col cols="2" class="mt-2">
            <span><img src='@/assets/icons/i-icon.svg' /></span>
          </v-col>
          <v-col cols="10" class="info-text">
            <span >{{ $t('peselInfo') }}</span>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <div class="container row mt-0">
      <v-col cols="6" lg="6" class="text-left">
        <button
          type="button"
          @click="undo"
          class="grey-text semibold-font"
        >{{ $t('undo') | uppercase }}</button>
      </v-col>
      <v-col cols="6" lg="6" class="text-right">
        <button
          :disabled="validAllData || loading"
          @click="submit"
          type="submit"
          class="gradient-pink semibold-font"
        >{{ $t('next') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'pesel',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    locationCompany () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'RO'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'BG'
      } else {
        return 'PL'
      }
    },

    validAllData () {
      return this.validPesel
    },

    validPesel () {
      const pesel = this.creationDocument.attributes.pesel
      if (pesel === null || pesel === '') {
        return false
      }
      const reg = /^[0-9]{11}$/
      if (reg.test(pesel) === false) {
        return true
      } else {
        const dig = ('' + pesel).split('')
        let control = (1 * parseInt(dig[0], 10) + 3 * parseInt(dig[1], 10) + 7 * parseInt(dig[2], 10) + 9 * parseInt(dig[3], 10) + 1 * parseInt(dig[4], 10) + 3 * parseInt(dig[5], 10) + 7 * parseInt(dig[6], 10) + 9 * parseInt(dig[7], 10) + 1 * parseInt(dig[8], 10) + 3 * parseInt(dig[9], 10)) % 10
        if (control === 0) {
          control = 10
        }
        control = 10 - control
        if (parseInt(dig[10], 10) === control) {
          return false
        } else {
          return true
        }
      }
    }
  },
  mounted () {
    if (this.creationDocument) {
      this.$http.post(`/documents/${this.creatingDocumentState.document_id}/change_subprocess_step`, { data: { step_name: 'BPK_4' } })
        .then(response => {
          console.log(response)
        })
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'pesel',
        client: {
          pesel: this.creationDocument.attributes.pesel
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'pesel'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-agreement-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: justify;
    }
  }

  .info-text {
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: justify;
  }
</style>
