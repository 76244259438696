<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('globalIncomeIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('globalIncomeIntro') }}</p>
    </div>
    <div class="container mt-0">
      <v-row>
        <v-col class="mt-0 mb-2 pt-0 pb-0" cols="1" sm="21">
          <button class="grey-exclamation-mark-icon"></button>
        </v-col>
        <v-col class="mt-0 mb-2 pt-0 pb-0 ml-2" cols="10" sm="10">
          <p cols="10" class="base-info-description">
            {{ $t('globalIncomeIntro2') }} <u>{{ $t('globalIncomeIntro3') }}</u> {{ $t('globalIncomeIntro4') }} {{ $t('changedCountry.' + creationDocument.attributes.document_country ) }}.
          </p>
        </v-col>
      </v-row>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('didYouHavePolandIncome', { year: creationDocument.attributes.year }) }}</h3>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="container mt-0">
            <div class="row">
              <v-col cols="6" lg="6" class="text-left">
                <button :class="creationDocument.attributes.origin_country_income == false ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.origin_country_income = false">{{ $t('no') | uppercase }}</button>
              </v-col>
              <v-col cols="6" lg="6" class="text-left">
                <button :class="creationDocument.attributes.origin_country_income == true ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.origin_country_income = true">{{ $t('yes') | uppercase }}</button>
              </v-col>
            </div>
          </div>
        </v-row>
        <v-row v-if="creationDocument.attributes.origin_country_income == true">
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-0 pt-0 pb-0 text-center" cols="12" sm="12">
                <p cols="12" class="base-info-description text-left mt-4">{{ $t('enterGlobalIncome') }}</p>
              </v-col>
              <v-col cols="10" class="text-right">
                <vue-numeric class="numeric-base-form-input text-right" currency="" separator="space" v-bind:precision="2" v-model="creationDocument.attributes.origin_country_income_amount"></vue-numeric>
              </v-col>
              <v-col cols="1" class="text-left mt-3 ml-0 pl-0">
                <img src="@/assets/poland-flag.svg">
              </v-col>
              <v-col cols="1" class="text-left mt-3 ml-0 pl-0">
                <div>PLN</div>
              </v-col>
            </div>
          </div>
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-0 pt-0 pb-0 text-center" cols="12" sm="12">
                <p cols="12" class="base-info-description text-left mt-4">{{ $t('currentOriginIncome') }}</p>
              </v-col>
              <v-col cols="10" class="text-right">
                <vue-numeric class="numeric-base-form-input text-right" currency="" disabled separator="space" v-bind:precision="2" v-model="creationDocument.attributes.current_income_amount"></vue-numeric>
              </v-col>
              <v-col cols="1" class="text-left mt-3 ml-0 pl-0">
                <img src="@/assets/poland-flag.svg">
              </v-col>
              <v-col cols="1" class="text-left mt-3 ml-0 pl-0">
                <div>PLN</div>
              </v-col>
            </div>
          </div>
        </v-row>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="creationDocument.attributes.origin_country_income == null || loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'originCountryIncome',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument'])
  },
  mounted () {
    if (this.creationDocument) {
      this.$http.post(`/documents/${this.creatingDocumentState.document_id}/change_subprocess_step`, { data: { step_name: 'BPK_1' } })
        .then(response => {
          console.log(response)
        })
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'origin_country_income',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        origin_country_income: {
          origin_country_income: this.creationDocument.attributes.origin_country_income,
          origin_country_income_amount: this.creationDocument.attributes.origin_country_income_amount
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'origin_country_income'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .grey-exclamation-mark-icon {
    background: transparent url('./../../assets/icons/grey-exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
</style>
