<template>
  <div>
    <div class="container row text-center mt-0 pt-0 ml-1 pr-10">
      <v-col cols="10 text-left mt-0 pt-0 mr-0 pr-0">
        <h2 class="semibold-font mb-1 mt-3">{{ $t('regular_client_header') }}<br>{{ $t('regular_client_header2') }}</h2>
      </v-col>
      <v-col cols="2 text-left mt-2 pt-0 pl-0 ml-0">
        <img class="agreement-logo" src='@/assets/agreement.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="pl-4 base-info-description">{{ $t(correctRegularClientInfo) }}</p>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <p cols="12" class="pl-4 base-info-description">{{ $t(correctRegularClientInfo2) }}</p>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center mt-0 mb-0 pb-0 pt-0">
        <v-radio-group v-model="acceptRegularClient">
          <v-radio
            class="accept-regular-client-checkbox bold-label mb-0 pb-0"
            :label="`${$t(correctRegularClientYes)}`"
            :value="true"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </div>
    <div v-if="correctRegularClientYesInfo" class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center regular-client-info-cuf mb-0 pb-0 pt-0">
        <p cols="12" class="base-info-description grey-text">{{ $t(correctRegularClientYesInfo) }}</p>
      </v-col>
    </div>
    <div class="container regular-client-info-cuf mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center mt-0 mb-0 pb-0 pt-0">
        <v-radio-group v-model="acceptRegularClient">
          <v-radio
            class="accept-regular-client-checkbox bold-label mb-0 pb-0 mt-2"
            :label="`${$t(correctRegularClientNo)}`"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </div>
    <div v-if="correctRegularClientInfo3" class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center mt-0 mb-0 pb-0 pt-0">
        <p cols="12" class="base-info-description grey-text">{{ $t(correctRegularClientInfo3) }}</p>
      </v-col>
    </div>
    <div class="container row mt-0 ml-1">
      <v-col cols="12" class="text-center">
        <button
         :disabled="acceptRegularClient === null || loading"
         @click="submit"
          type="submit"
          class="main-button main-button-fixed"
        >{{ $t('confirm') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'regularClient',
  data () {
    return {
      acceptRegularClient: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),
    ...mapGetters(['currentClient']),

    validAllData () {
      return true
    },

    correctRegularClientInfo () {
      if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'regular_client_info_cuf'
      } else {
        return 'regular_client_info'
      }
    },
    correctRegularClientInfo2 () {
      if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'regular_client_info2_cuf'
      } else {
        return 'regular_client_info2'
      }
    },
    correctRegularClientYes () {
      if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'regular_client_yes_cuf'
      } else {
        return 'regular_client_yes'
      }
    },
    correctRegularClientNo () {
      if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'regular_client_no_cuf'
      } else {
        return 'regular_client_no'
      }
    },
    correctRegularClientInfo3 () {
      if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return null
      } else {
        return 'regular_client_info3'
      }
    },
    correctRegularClientYesInfo () {
      if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'regular_client_yes_info_cuf'
      } else {
        return null
      }
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/regular_client`,
        {
          accept_regular_client: this.acceptRegularClient
        })
        .then(response => {
          this.creatingDocumentState.state = 'regular_client'
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-regular-client-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      line-height: 24px !important;
      font-weight: 600 !important;
      text-align: left !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }

  .promotion-more-info {
    margin-top: -30px;
  }

  .regular-client-info-cuf {
    margin-top: -20px;
  }

  .agreement-logo {
    width: 80px;
    height: 80px;
  }

   .link-container {
      display: flex;
      font-size: 18px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #e44d26;
      text-decoration: underline !important;
  }
</style>
