<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('maritalStatusIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('maritalStatusIntro') }}</p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div v-if="!displayDetails">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="8" sm="8">
            <h3 class="semibold-font">{{ $t('maritalStatusIntroQuestion') }}</h3>
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="row">
            <v-col cols="6" lg="6" class="text-left">
              <button :class="creationDocument.attributes.marital_status == 'single' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.marital_status = 'single'">{{ $t('single') }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button :class="creationDocument.attributes.marital_status == 'married' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.marital_status = 'married'">{{ $t('married') }}</button>
            </v-col>
            <v-col cols="5" lg="5" class="text-left">
              <button class="pl-1 pr-1" :class="creationDocument.attributes.marital_status == 'separation' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.marital_status = 'separation'">{{ $t('separation') }}</button>
            </v-col>
            <v-col cols="7" lg="7" class="text-left">
              <button :class="creationDocument.attributes.marital_status == 'divorced' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.marital_status = 'divorced'">{{ $t('divorced') }}</button>
            </v-col>
            <v-col cols="7" lg="7" class="text-left">
              <button class="pl-1 pr-1" :class="creationDocument.attributes.marital_status == 'concubinage' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.marital_status = 'concubinage'">{{ $t('concubinage') }}</button>
            </v-col>
            <v-col cols="5" lg="5" class="text-left">
              <button class="pl-1 pr-1" :class="creationDocument.attributes.marital_status == 'widower' ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.marital_status = 'widower'">{{ $t('widower') }}</button>
            </v-col>
          </div>
          <!-- SEPARATION -->
          <div class="row mt-6" v-if="creationDocument.attributes.marital_status == 'separation'">
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('separationDate') }}</div>
              <v-menu
                ref="dateChangeMartialMenu"
                v-model="dateChangeMartialMenu"
                class="birthday-menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDateChangeMartial"
                    prepend-inner-icon="mdi-calendar"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    class="base-form-input"
                    hide-details="true"
                    outlined
                    required
                    readonly
                    @blur="creationDocument.attributes.date_change_marital_status = parseDate(formatDateChangeMartial)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="creationDocument.attributes.date_change_marital_status"
                  no-title
                  :locale="userLocale"
                  @input="handleDateChangeMartialSelection"
                ></v-date-picker>
              </v-menu>
              <div class='error-notice' v-if="formatDateChangeMartial && !validDateChangeMartial()">
                {{ $t('wrongSeparationDate') }}
              </div>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                :disabled="!validAllDataSeparation || loading"
                @click="submitSeparation"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              <v-progress-circular
                v-if="loading"
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              </button>
            </v-col>
          </div>
          <!-- SEPARATION -->
          <!-- SINGIEL -->
          <div class="row mt-6" v-if="creationDocument.attributes.marital_status == 'single'">
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                :disabled="loading"
                @click="submitSingle"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              <v-progress-circular
                v-if="loading"
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              </button>
            </v-col>
          </div>
          <!-- SINGIEL -->
          <!-- WIDOWER -->
          <div class="row mt-6" v-if="creationDocument.attributes.marital_status == 'widower'">
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('widowerDate') }}</div>
              <v-menu
                ref="dateChangeMartialMenu"
                v-model="dateChangeMartialMenu"
                class="birthday-menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDateChangeMartial"
                    prepend-inner-icon="mdi-calendar"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    class="base-form-input"
                    hide-details="true"
                    outlined
                    required
                    readonly
                    @blur="creationDocument.attributes.date_change_marital_status = parseDate(formatDateChangeMartial)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="creationDocument.attributes.date_change_marital_status"
                  no-title
                  :locale="userLocale"
                  @input="handleDateChangeMartialSelection"
                ></v-date-picker>
              </v-menu>
              <div class='error-notice' v-if="formatDateChangeMartial && !validDateChangeMartial()">
                {{ $t('wrongWidowerDate') }}
              </div>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                :disabled="!validAllDataWidower || loading"
                @click="submitWidower"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              <v-progress-circular
                v-if="loading"
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              </button>
            </v-col>
          </div>
          <!-- WIDOWER -->
          <div class="container row mt-0">
            <v-col cols="6" lg="6" class="text-left"  v-if="creationDocument.attributes.marital_status == 'married' || creationDocument.attributes.marital_status == 'divorced' || creationDocument.attributes.marital_status == 'concubinage'">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right" v-if="creationDocument.attributes.marital_status == 'married' || creationDocument.attributes.marital_status == 'divorced' || creationDocument.attributes.marital_status == 'concubinage'">
              <button
                :disabled="maritalStatus"
                @click="displayDetails = true"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              </button>
            </v-col>
          </div>
        </v-row>
      </div>
      <div v-if="displayDetails">
        <!-- MARRIED -->
        <div v-if="creationDocument.attributes.marital_status == 'married'">
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="8" sm="8">
              <h3 class="semibold-font">{{ $t('marriedDetailsHeader1') }}</h3>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-left" cols="12" sm="12">
              <p cols="12" class="base-info-description text-left mt-4">{{ $t('completeData') }} <u>{{ $t('completeDataAboutMarried') }}</u></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('marriedDate') }}</div>
              <v-menu
                ref="dateChangeMartialMenu"
                v-model="dateChangeMartialMenu"
                class="birthday-menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDateChangeMartial"
                    prepend-inner-icon="mdi-calendar"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    class="base-form-input"
                    hide-details="true"
                    outlined
                    required
                    readonly
                    @blur="creationDocument.attributes.date_change_marital_status = parseDate(formatDateChangeMartial)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="creationDocument.attributes.date_change_marital_status"
                  no-title
                  :locale="userLocale"
                  @input="handleDateChangeMartialSelection"
                ></v-date-picker>
              </v-menu>
              <div class='error-notice' v-if="formatDateChangeMartial && !validDateChangeMartial()">
                {{ $t('wrongMarriedDate') }}
              </div>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('firstName') }}</div>
              <v-text-field
                class="base-form-input"
                hide-details="true"
                outlined
                required
                autofocus
                v-model="creationDocument.attributes.spouse_first_name"
              ></v-text-field>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_first_name && validateCyrillic(creationDocument.attributes.spouse_first_name)">
                {{ $t('dont_use_cyrillic') }}
              </div>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_first_name && validateNumbers(creationDocument.attributes.spouse_first_name)">
                {{ $t('dont_use_numbers') }}
              </div>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('lastName') }}</div>
              <v-text-field
                class="base-form-input"
                hide-details="true"
                outlined
                required
                autofocus
                v-model="creationDocument.attributes.spouse_last_name"
              ></v-text-field>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_last_name && validateCyrillic(creationDocument.attributes.spouse_last_name)">
                {{ $t('dont_use_cyrillic') }}
              </div>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_last_name && validateNumbers(creationDocument.attributes.spouse_last_name)">
                {{ $t('dont_use_numbers') }}
              </div>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('birthday') }}</div>
              <v-menu
                ref="birthdayMenu"
                v-model="birthdayMenu"
                class="birthday-menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatBirthday"
                    prepend-inner-icon="mdi-calendar"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    class="base-form-input"
                    hide-details="true"
                    outlined
                    required
                    readonly
                    @blur="creationDocument.attributes.spouse_birthday = parseDate(formatBirthday)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="creationDocument.attributes.spouse_birthday"
                  no-title
                  :locale="userLocale"
                  @input="handleDateSelection"
                ></v-date-picker>
              </v-menu>
              <div class='error-notice' v-if="formatBirthday && !validBirthday()">
                {{ $t('wrong_birthday') }}
              </div>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                :disabled="!validAllDataMarried || loading"
                @click="submitMarried"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              <v-progress-circular
                v-if="loading"
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              </button>
            </v-col>
          </v-row>
        </div>
        <!-- MARRIED -->
        <!-- DIVORCED -->
        <div v-if="creationDocument.attributes.marital_status == 'divorced'">
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="8" sm="8">
              <h3 class="semibold-font">{{ $t('divorcedDetailsHeader1') }}</h3>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-left" cols="12" sm="12">
              <p cols="12" class="base-info-description text-left mt-4">{{ $t('completeData') }} <u>{{ $t('completeDataAboutDivorced') }}</u></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('divorcedDate') }}</div>
              <v-menu
                ref="dateChangeMartialMenu"
                v-model="dateChangeMartialMenu"
                class="birthday-menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDateChangeMartial"
                    prepend-inner-icon="mdi-calendar"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    class="base-form-input"
                    hide-details="true"
                    outlined
                    required
                    readonly
                    @blur="creationDocument.attributes.date_change_marital_status = parseDate(formatDateChangeMartial)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="creationDocument.attributes.date_change_marital_status"
                  no-title
                  :locale="userLocale"
                  @input="handleDateChangeMartialSelection"
                ></v-date-picker>
              </v-menu>
              <div class='error-notice' v-if="formatDateChangeMartial && !validDateChangeMartial()">
                {{ $t('wrongDivorcedDate') }}
              </div>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('firstName') }}</div>
              <v-text-field
                class="base-form-input"
                hide-details="true"
                outlined
                required
                autofocus
                v-model="creationDocument.attributes.spouse_first_name"
              ></v-text-field>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_first_name && validateCyrillic(creationDocument.attributes.spouse_first_name)">
                {{ $t('dont_use_cyrillic') }}
              </div>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_first_name && validateNumbers(creationDocument.attributes.spouse_first_name)">
                {{ $t('dont_use_numbers') }}
              </div>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('lastName') }}</div>
              <v-text-field
                class="base-form-input"
                hide-details="true"
                outlined
                required
                autofocus
                v-model="creationDocument.attributes.spouse_last_name"
              ></v-text-field>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_last_name && validateCyrillic(creationDocument.attributes.spouse_last_name)">
                {{ $t('dont_use_cyrillic') }}
              </div>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_last_name && validateNumbers(creationDocument.attributes.spouse_last_name)">
                {{ $t('dont_use_numbers') }}
              </div>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                :disabled="!validAllDataDivorced || loading"
                @click="submitDivorced"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              <v-progress-circular
                v-if="loading"
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              </button>
            </v-col>
          </v-row>
        </div>
        <!-- DIVORCED -->
        <!-- concubinage -->
        <div v-if="creationDocument.attributes.marital_status == 'concubinage'">
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="2" sm="2">
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="8" sm="8">
              <h3 class="semibold-font">{{ $t('concubinageDetailsHeader1') }}</h3>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-left" cols="12" sm="12">
              <p cols="12" class="base-info-description text-left mt-4">{{ $t('completeData') }} <u>{{ $t('completeDataAboutConcubinage') }}</u></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('firstName') }}</div>
              <v-text-field
                class="base-form-input"
                hide-details="true"
                outlined
                required
                autofocus
                v-model="creationDocument.attributes.spouse_first_name"
              ></v-text-field>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_first_name && validateCyrillic(creationDocument.attributes.spouse_first_name)">
                {{ $t('dont_use_cyrillic') }}
              </div>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_first_name && validateNumbers(creationDocument.attributes.spouse_first_name)">
                {{ $t('dont_use_numbers') }}
              </div>
            </v-col>
            <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
              <div class="base-info-description mb-1">{{ $t('lastName') }}</div>
              <v-text-field
                class="base-form-input"
                hide-details="true"
                outlined
                required
                autofocus
                v-model="creationDocument.attributes.spouse_last_name"
              ></v-text-field>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_last_name && validateCyrillic(creationDocument.attributes.spouse_last_name)">
                {{ $t('dont_use_cyrillic') }}
              </div>
              <div class='error-notice' v-if="creationDocument.attributes.spouse_last_name && validateNumbers(creationDocument.attributes.spouse_last_name)">
                {{ $t('dont_use_numbers') }}
              </div>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button
                type="button"
                @click="undo"
                class="grey-text semibold-font"
              >{{ $t('undo') | uppercase }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-right">
              <button
                :disabled="!validAllDataConcubinage || loading"
                @click="submitConcubinage"
                class="gradient-pink semibold-font"
              >{{ $t('next') | uppercase }}
              <v-progress-circular
                v-if="loading"
                :size="15"
                color="grey"
                indeterminate
              ></v-progress-circular>
              </button>
            </v-col>
          </v-row>
        </div>
        <!-- concubinage -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'maritalStatus',
  data () {
    return {
      loading: false,
      displayDetails: false,
      maritalStatus: null,
      dateChangeMartialMenu: false,
      formatDateChangeMartial: null,
      birthdayMenu: false,
      formatBirthday: null
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllDataMarried () {
      return (this.creationDocument.attributes.spouse_first_name != null && !this.validateCyrillic(this.creationDocument.attributes.spouse_first_name) && !this.validateNumbers(this.creationDocument.attributes.spouse_first_name)) &&
        (this.creationDocument.attributes.spouse_last_name != null && !this.validateCyrillic(this.creationDocument.attributes.spouse_last_name) && !this.validateNumbers(this.creationDocument.attributes.spouse_last_name)) &&
        this.validBirthday() &&
        this.validDateChangeMartial()
    },
    validAllDataDivorced () {
      return (this.creationDocument.attributes.spouse_first_name != null && !this.validateCyrillic(this.creationDocument.attributes.spouse_first_name) && !this.validateNumbers(this.creationDocument.attributes.spouse_first_name)) &&
        (this.creationDocument.attributes.spouse_last_name != null && !this.validateCyrillic(this.creationDocument.attributes.spouse_last_name) && !this.validateNumbers(this.creationDocument.attributes.spouse_last_name)) &&
        this.validDateChangeMartial()
    },
    validAllDataConcubinage () {
      return (this.creationDocument.attributes.spouse_first_name != null && !this.validateCyrillic(this.creationDocument.attributes.spouse_first_name) && !this.validateNumbers(this.creationDocument.attributes.spouse_first_name)) &&
        (this.creationDocument.attributes.spouse_last_name != null && !this.validateCyrillic(this.creationDocument.attributes.spouse_last_name) && !this.validateNumbers(this.creationDocument.attributes.spouse_last_name))
    },
    validAllDataSeparation () {
      return this.validDateChangeMartial()
    },
    validAllDataWidower () {
      return this.validDateChangeMartial()
    },

    userLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    creationDocument (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.spouse_birthday)
      this.formatDateChangeMartial = this.formatDate(this.creationDocument.attributes.date_change_marital_status)
    },
    'creationDocument.attributes.spouse_birthday' (val) {
      this.formatBirthday = this.formatDate(this.creationDocument.attributes.spouse_birthday)
    },
    'creationDocument.attributes.date_change_marital_status' (val) {
      this.formatDateChangeMartial = this.formatDate(this.creationDocument.attributes.date_change_marital_status)
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    validateCyrillic (value) {
      const cyrillicPattern = /[\u0400-\u04FF]/
      return cyrillicPattern.test(value)
    },

    validateNumbers (value) {
      const numberPattern = /\d/
      return numberPattern.test(value)
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    handleDateChangeMartialSelection (date) {
      this.creationDocument.attributes.date_change_marital_status = date
      this.formatDateChangeMartial = this.formatDate(date)
      this.dateChangeMartialMenu = false
    },

    validDateChangeMartial () {
      if (this.formatDateChangeMartial) {
        const comparisonDate = new Date('1920', '01', '01')

        const dateParts = this.formatDateChangeMartial.split('/')
        const day = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        const year = parseInt(dateParts[2], 10)
        const inputDate = new Date(year, month, day)

        return inputDate > comparisonDate
      } else {
        return false
      }
    },

    handleDateSelection (date) {
      this.creationDocument.attributes.spouse_birthday = date
      this.formatBirthday = this.formatDate(date)
      this.birthdayMenu = false
    },

    validBirthday () {
      if (this.formatBirthday) {
        const comparisonDate = new Date('1920', '01', '01')

        const dateParts = this.formatBirthday.split('/')
        const day = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        const year = parseInt(dateParts[2], 10)
        const inputDate = new Date(year, month, day)

        return inputDate > comparisonDate
      } else {
        return false
      }
    },

    submitMarried () {
      this.loading = true
      const dataObject = {
        state: 'martial_status_married',
        client: {
          marital_status: this.creationDocument.attributes.marital_status,
          date_change_marital_status: this.creationDocument.attributes.date_change_marital_status,
          spouse_first_name: this.creationDocument.attributes.spouse_first_name,
          spouse_last_name: this.creationDocument.attributes.spouse_last_name,
          spouse_birthday: this.creationDocument.attributes.spouse_birthday
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'martial_status_married'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    submitDivorced () {
      this.loading = true
      const dataObject = {
        state: 'martial_status_divorced',
        client: {
          marital_status: this.creationDocument.attributes.marital_status,
          date_change_marital_status: this.creationDocument.attributes.date_change_marital_status,
          spouse_first_name: this.creationDocument.attributes.spouse_first_name,
          spouse_last_name: this.creationDocument.attributes.spouse_last_name
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'martial_status_divorced'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    submitConcubinage () {
      this.loading = true
      const dataObject = {
        state: 'martial_status_concubinage',
        client: {
          marital_status: this.creationDocument.attributes.marital_status,
          spouse_first_name: this.creationDocument.attributes.spouse_first_name,
          spouse_last_name: this.creationDocument.attributes.spouse_last_name
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'martial_status_concubinage'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    submitSeparation () {
      this.loading = true
      const dataObject = {
        state: 'martial_status_separation',
        client: {
          marital_status: this.creationDocument.attributes.marital_status,
          date_change_marital_status: this.creationDocument.attributes.date_change_marital_status
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'martial_status_separation'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    submitWidower () {
      this.loading = true
      const dataObject = {
        state: 'martial_status_widower',
        client: {
          marital_status: this.creationDocument.attributes.marital_status,
          date_change_marital_status: this.creationDocument.attributes.date_change_marital_status
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'martial_status_widower'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    submitSingle () {
      this.loading = true
      const dataObject = {
        state: 'martial_status_single',
        client: {
          marital_status: this.creationDocument.attributes.marital_status
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'martial_status_single'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
</style>
