<template>
  <div>
    <div class="container row text-center mt-0 pt-0 ml-1 pr-10">
      <v-col cols="9 text-left mt-0 pt-0">
        <h2 class="semibold-font mb-1 mt-3">{{ $t('acceptAgreementHeader') }}<br>{{ $t('acceptAgreementHeader2') }}</h2>
      </v-col>
      <v-col cols="3 text-left mt-2 pt-0">
        <img class="agreement-logo" src='@/assets/agreement.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="pl-4 base-info-description">{{ $t('acceptAgreementDescription') }}<br><br>{{ $t('acceptAgreementDescription2') }}</p>
    </div>
    <div class="container mt-0">
      <v-col v-if="agreementStatus === 'done'" cols="12" lg="12" class="text-center mt-0 pt-0">
        <a :href="agreementUrl" target="_blank" class="link-container gradient-pink">
          <span class="download-agreement-icon mt-1"></span>
          <u class="gradient-pink">{{ $t('agreement_title') }}</u>
        </a>
      </v-col>
      <v-col v-if="agreementStatus === 'in_progress'" class="inline-flex align-center mt-0 pt-0">
        <div class="generating-agreement">
          <span class="mt-0 mr-2">
            <v-progress-circular
              :size="25"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </span>
          <div class="">{{ $t('generating_agreement') }}</div>
        </div>
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <p cols="12" class="pl-4 base-info-description">{{ $t('select_answers') }}</p>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center">
        <v-checkbox
          class="accept-agreement-checkbox mb-0 pb-0"
          v-model="usePromotion"
          :disabled="agreementStatus === 'in_progress'"
          :label="`${$t('use_promotion')}`"
        ></v-checkbox>
      </v-col>
      <div class="promotion-info-container">
        <p class="pl-4 promotion-more-info base-info-description grey-text">{{ $t('promotion_more_info') }}
        <a :href="`${promotionLink}`" target="_blank" class="promotion-more-info base-info-description grey-text grey-text-link">{{ $t('promotion_here') }}</a></p>
      </div>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center mt-0 mb-0 pb-0 pt-0">
        <v-checkbox
          class="accept-agreement-checkbox mb-0 pb-0"
          v-model="downloadAgreement"
          :disabled="agreementStatus === 'in_progress'"
          :label="`${$t('i_have_downloaded_agreement')}`"
        ></v-checkbox>
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center mt-0 mb-0 pb-0 pt-0">
        <v-checkbox
          class="accept-agreement-checkbox mb-0 pb-0"
          v-model="processMyData"
          :disabled="agreementStatus === 'in_progress'"
          :label="`${$t('process_my_data')}`"
        ></v-checkbox>
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0">
      <v-col cols="12" lg="12" class="text-center mt-0 mb-0 pb-0 pt-0">
        <p cols="12" class="base-info-description grey-text">{{ $t('agreement_info') }}</p>
      </v-col>
    </div>
    <div class="container row mt-0 ml-1">
      <v-col cols="12" class="text-center">
        <button
         :disabled="!processMyData || !downloadAgreement || loading || agreementStatus === 'in_progress'"
         @click="submit"
          type="submit"
          class="main-button main-button-fixed"
        >{{ $t('confirm') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  data () {
    return {
      usePromotion: false,
      downloadAgreement: false,
      processMyData: false,
      loading: false,
      interval: null,
      agreementStatus: 'in_progress',
      agreementUrl: null
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return true
    },

    promotionLink () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'https://client-panel-files.s3.eu-west-1.amazonaws.com/Termenii_si_conditiile_Campaniei_Promotionale_18122024.docx'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'https://client-panel-files.s3.eu-west-1.amazonaws.com/Regulations_of_the_Promotional_Campaign_BG_18122024.docx'
      } else if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'https://client-panel-files.s3.eu-west-1.amazonaws.com/Regulamin_Akcji_Promocyjnej_CUF_18122024.docx'
      } else {
        return 'https://client-panel-files.s3.eu-west-1.amazonaws.com/Regulamin_Akcji_Promocyjnej_euro_tax.docx'
      }
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)

    const fetchAgreementStatus = () => {
      this.$http.get(`documents/${this.creatingDocumentState.document_id}/generate_agreement`)
        .then(response => {
          console.log(response)
          if (response.data.status !== 'in_progress') {
            this.agreementStatus = response.data.status
            this.agreementUrl = response.data.url
            clearInterval(this.interval)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    // Call the function immediately
    fetchAgreementStatus()

    // Set up the interval
    this.interval = setInterval(fetchAgreementStatus, 1000)
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  methods: {
    submit () {
      this.loading = true
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/accept_agreement`,
        {
          use_promotion: this.usePromotion,
          download_agreement: this.downloadAgreement,
          process_my_data: this.processMyData
        })
        .then(response => {
          this.creatingDocumentState.state = 'accept_agreement'
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-agreement-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: left !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }

  .promotion-more-info {
    z-index: 1000 !important;
    margin-top: -30px;
  }

  .agreement-logo {
    width: 80px;
    height: 80px;
  }

  .grey-text-link {
    color: #B4B4B4 !important;
  }

  .promotion-info-container {
    display: flex;
    align-items: center;
  }

  .link-container {
    display: flex;
    font-size: 18px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #e44d26;
    text-decoration: underline !important;
  }

  .generating-agreement {
    display: inline-flex;
    font-size: 18px;
  }
</style>
