<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('globalIncomeIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('globalIncomeIntro') }}</p>
    </div>
    <div class="container mt-0">
      <v-row>
        <v-col class="mt-0 mb-2 pt-0 pb-0" cols="1" sm="21">
          <button class="grey-exclamation-mark-icon"></button>
        </v-col>
        <v-col class="mt-0 mb-2 pt-0 pb-0 ml-2" cols="10" sm="10">
          <p cols="10" class="base-info-description">
            {{ $t('globalIncomeIntro2') }} <u>{{ $t('globalIncomeIntro3') }}</u> {{ $t('globalIncomeIntro4') }} {{ $t('changedCountry.' + creationDocument.attributes.document_country ) }}.
          </p>
        </v-col>
      </v-row>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('didYouHaveAnotherIncome', { year: creationDocument.attributes.year }) }}</h3>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="container mt-0">
            <div class="row">
              <v-col cols="6" lg="6" class="text-left">
                <button :class="creationDocument.attributes.global_income == false ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.global_income = false">{{ $t('no') | uppercase }}</button>
              </v-col>
              <v-col cols="6" lg="6" class="text-left">
                <button :class="creationDocument.attributes.global_income == true ? 'button-gradient-border' : 'button-border'" @click="creationDocument.attributes.global_income = true">{{ $t('yes') | uppercase }}</button>
              </v-col>
            </div>
          </div>
        </v-row>
        <v-row v-if="creationDocument.attributes.global_income == true">
          <div class="container mt-4">
            <div class="row" v-for="(global_income_amount, index) in creationDocument.attributes.global_income_amounts" :key="index">
              <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
                <div class="base-info-description mb-1">{{ $t('selectCountryIncome') }}</div>
                <v-select
                  v-if="global_income_amount.country"
                  v-model="global_income_amount.country"
                  class="outlined__input gradient-border"
                  :items="filteredCountries"
                  outlined
                >
                </v-select>
                <v-select
                  v-if="!global_income_amount.country"
                  v-model="global_income_amount.country"
                  class="outlined__input gradient-border"
                  :items="filteredCountriesForSelect"
                  outlined
                >
                </v-select>
              </v-col>
              <v-col class="mt-0 mb-0 pt-0 pb-0 text-center" cols="12" sm="12">
                <p cols="12" class="base-info-description text-left mt-4">{{ $t('enterGlobalAnotherCountryIncome') }}</p>
              </v-col>
              <v-col cols="10" class="text-right mt-0">
                <vue-numeric class="numeric-base-form-input text-right" currency="" separator="space" v-bind:precision="2" v-model="global_income_amount.income"></vue-numeric>
              </v-col>
              <v-col cols="1" class="text-left mt-3 ml-0 pl-0">
                <img src="@/assets/poland-flag.svg">
              </v-col>
              <v-col cols="1" class="text-left mt-3 ml-0 pl-0">
                <div>PLN</div>
              </v-col>
            </div>
            <div class="row" @click="addAnotherGlobalIncomeAmount()">
              <button class="mobile-menu-item plus-icon mr-1"></button>
              <u>{{ $t('addAnotherCountryIncome') }}</u>
            </div>
          </div>
        </v-row>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="creationDocument.attributes.global_income == null || loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'globalIncome',
  data () {
    return {
      loading: false,
      countries: [
        { value: 'Austria', text: this.$i18n.t('countries.Austria'), textPl: 'Austria' },
        { value: 'Belgium', text: this.$i18n.t('countries.Belgium'), textPl: 'Belgia' },
        { value: 'Bulgaria', text: this.$i18n.t('countries.Bulgaria'), textPl: 'Bułgaria' },
        { value: 'Denmark', text: this.$i18n.t('countries.Denmark'), textPl: 'Dania' },
        { value: 'France', text: this.$i18n.t('countries.France'), textPl: 'Francja' },
        { value: 'Netherlands', text: this.$i18n.t('countries.Netherlands'), textPl: 'Holandia' },
        { value: 'Ireland', text: this.$i18n.t('countries.Ireland'), textPl: 'Irlandia' },
        { value: 'Germany', text: this.$i18n.t('countries.Germany'), textPl: 'Niemcy' },
        { value: 'Norway', text: this.$i18n.t('countries.Norway'), textPl: 'Norwegia' },
        { value: 'Poland', text: this.$i18n.t('countries.Poland'), textPl: 'Polska' },
        { value: 'Romania', text: this.$i18n.t('countries.Romania'), textPl: 'Rumunia' },
        { value: 'Sweden', text: this.$i18n.t('countries.Sweden'), textPl: 'Szwecja' },
        { value: 'United Kingdom', text: this.$i18n.t('countries.United_Kingdom'), textPl: 'Wielka Brytania' },
        { value: 'Ukraine', text: this.$i18n.t('countries.Ukraine'), textPl: 'Ukrainia' },
        { value: 'Russia', text: this.$i18n.t('countries.Russia'), textPl: 'Rosja' },
        { value: 'Belarussia', text: this.$i18n.t('countries.Belarussia'), textPl: 'Białoruś' },
        { value: 'Switzerland', text: this.$i18n.t('countries.Switzerland'), textPl: 'Szwajcaria' },
        { value: 'Czechia', text: this.$i18n.t('countries.Czechia'), textPl: 'Czechy' },
        { value: 'Slovakia', text: this.$i18n.t('countries.Slovakia'), textPl: 'Słowacja' },
        { value: 'Italy', text: this.$i18n.t('countries.Italy'), textPl: 'Włochy' }
      ]
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    filteredCountries () {
      return this.countries.filter(country =>
        (country.textPl !== this.creationDocument.attributes.document_country) &&
        (country.value !== this.creationDocument.attributes.origin_country)
      )
    },
    filteredCountriesForSelect () {
      return this.countries.filter(country =>
        (country.textPl !== this.creationDocument.attributes.document_country) &&
        (country.value !== this.creationDocument.attributes.origin_country) &&
        !this.selectedCountries.includes(country.value)
      )
    },
    selectedCountries () {
      return this.creationDocument.attributes.global_income_amounts.map(income => income.country)
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    addAnotherGlobalIncomeAmount () {
      this.creationDocument.attributes.global_income_amounts.push({ country: '', income: 0 })
    },
    submit () {
      this.loading = true
      const dataObject = {
        state: 'global_income_amounts',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        global_income_amounts: this.creationDocument.attributes.global_income_amounts
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'global_income_amounts'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .grey-exclamation-mark-icon {
    background: transparent url('./../../assets/icons/grey-exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
</style>
