<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('greatMessage') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/icons/chart-up.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="base-info-description">{{ $t('zorgtoeslagDescription') }}</p>
    </div>
    <div class="container mt-0 mb-0">
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('doYouWantZorgtoeslag') }}</h3>
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0 pr-0" cols="12" sm="12">
                <v-radio-group v-model="needZorgtoeslag">
                  <v-radio :label="$t('zorgtoeslagYes')" value="true"></v-radio>
                  <v-radio :label="$t('zorgtoeslagNo')" value="already_obtains"></v-radio>
                  <v-radio :label="$t('notInterested')" value="no"></v-radio>
                </v-radio-group>
              </v-col>
            </div>
          </div>
        </v-row>
        <div v-if="needZorgtoeslag == 'true'">
          <v-row>
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
              <p cols="12" class="base-info-description text-left mt-4">{{ $t('zorgtoeslagEntirePeriod') }}</p>
              <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
            </v-col>
          </v-row>
          <div class="row">
            <v-col cols="6" lg="6" class="text-left">
              <button :class="allEntirePeriod == false ? 'button-gradient-border' : 'button-border'" @click="allEntirePeriod = false">{{ $t('no') }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button :class="allEntirePeriod == true ? 'button-gradient-border' : 'button-border'" @click="allEntirePeriod = true">{{ $t('yes') }}</button>
            </v-col>
          </div>
          <div class="row" v-if="allEntirePeriod == false">
            <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
              <p cols="12" class="base-info-description text-center mt-4">{{ $t('zorgtoeslagSelectYear') }}</p>
            </v-col>
            <v-col cols="6" lg="6" class="text-left" v-for="(year) in years" :key="year">
              <button :class="zorgtoeslagYears == year ? 'button-gradient-border' : 'button-border'" @click="zorgtoeslagYears = year">{{ year }}</button>
            </v-col>
          </div>
        </div>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="!validAllData || loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'eog',
  data () {
    return {
      loading: false,
      needZorgtoeslag: null,
      allEntirePeriod: null,
      zorgtoeslagYears: null
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    years () {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth() // 0 = January, 11 = December

      if (currentMonth >= 8) { // September is month 8
        return [currentYear]
      } else {
        return [currentYear, currentYear - 1]
      }
    },

    validAllData () {
      return this.needZorgtoeslag !== 'true' || (this.needZorgtoeslag === 'true' && this.allEntirePeriod === true) || (this.needZorgtoeslag === 'true' && this.allEntirePeriod === false && this.zorgtoeslagYears !== null)
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'need_zorgtoeslag',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        client_interested_in_zorgtoeslag: this.needZorgtoeslag,
        all_entire_period: this.allEntirePeriod,
        zorgtoeslag_years: this.zorgtoeslagYears
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'need_zorgtoeslag'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .grey-exclamation-mark-icon {
    background: transparent url('./../../assets/icons/grey-exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
</style>
