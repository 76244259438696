<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('additionalServicesIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('polishPitIntro') }}</p>
    </div>
    <div class="container mt-0">
      <v-row>
        <v-col class="mt-0 mb-2 pt-0 pb-0" cols="1" sm="21">
          <button class="grey-exclamation-mark-icon"></button>
        </v-col>
        <v-col class="mt-0 mb-2 pt-0 pb-0 ml-2" cols="10" sm="10">
          <p cols="10" class="base-info-description">
            {{ $t('polishPitIntro2') }}
          </p>
        </v-col>
      </v-row>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('documentsFromTheEmployer') }}</h3>
          </v-col>
          <v-col class="mt-0 mb-0 pt-0 pb-0 text-center" cols="12" sm="12">
            <p cols="12" class="base-info-description mt-4">{{ $t('documentsFromTheEmployerDescription') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="container mt-0">
            <div class="row">
              <v-col class="mt-0 mb-2 pt-0 pb-0 pr-0" cols="12" sm="12">
                <v-radio-group v-model="creationDocument.attributes.employment_data_who_provides">
                  <v-radio :label="$t('emplymentDataClient')" value="company"></v-radio>
                  <v-radio :label="$t('emplymentDataCompany')" value="client"></v-radio>
                </v-radio-group>
              </v-col>
            </div>
          </div>
        </v-row>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="creationDocument.attributes.employment_data_who_provides == null || loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'eog',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument'])
  },
  mounted () {
    if (this.creationDocument) {
      this.$http.post(`/documents/${this.creatingDocumentState.document_id}/change_subprocess_step`, { data: { step_name: 'BPK_5' } })
        .then(response => {
          console.log(response)
        })
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'employment_data_who_provides',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        employment_data_who_provides: this.creationDocument.attributes.employment_data_who_provides
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'employment_data_who_provides'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .grey-exclamation-mark-icon {
    background: transparent url('./../../assets/icons/grey-exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
</style>
