<template>
  <div>
    <div class="container text-center mt-2">
      <h2 class="semibold-font mb-1 mt-3">{{ $t('greatMessage') }}</h2>
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/icons/chart-up.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="base-info-description">{{ $t('zorgtoeslagDescription') }}</p>
    </div>
    <div class="container mt-0 mb-0">
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <div>
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0 text-center" cols="12" sm="12">
            <h3 class="semibold-font">{{ $t('zorgtoeslagIncome') }}</h3>
            <p cols="12" class="base-info-description text-center mt-4">{{ $t('selectAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <div class="row">
            <v-col cols="6" lg="6" class="text-left">
              <button :class="moreThan25144 == false ? 'button-gradient-border' : 'button-border'" @click="moreThan25144 = false">{{ $t('no') }}</button>
            </v-col>
            <v-col cols="6" lg="6" class="text-left">
              <button :class="moreThan25144 == true ? 'button-gradient-border' : 'button-border'" @click="moreThan25144 = true">{{ $t('yes') }}</button>
            </v-col>
          </div>
        </v-row>
        <div class="container mt-0 mb-0 pb-0 pt-0 mt-0">
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <v-row class="mt-6">
              <v-col cols="2" class="mt-2">
                <span><img src='@/assets/icons/i-icon.svg' /></span>
              </v-col>
              <v-col cols="10" class="info-text">
                <span >{{ $t('zorgtoeslagIncomeInfo') }}</span>
              </v-col>
            </v-row>
          </v-col>
        </div>
        <div class="container row mt-0">
          <v-col cols="6" lg="6" class="text-left">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right">
            <button
              :disabled="moreThan25144 == null || loading"
              @click="submit()"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            </button>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'zorgtoeslagIncome',
  data () {
    return {
      loading: false,
      moreThan25144: null
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument'])
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      const dataObject = {
        state: 'total_income_above_25k',
        client: {
          have_children: this.creationDocument.attributes.have_children
        },
        total_income_above_25k: this.moreThan25144.toString()
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'total_income_above_25k'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .grey-exclamation-mark-icon {
    background: transparent url('./../../assets/icons/grey-exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
</style>
