<template>
  <div>
    <div class="container text-center mt-2">
      <v-col cols="12 text-center mt-3">
        <img src='@/assets/thankYouIcone.svg' />
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0">
      <p cols="12" class="pl-4 base-info-description">{{ $t('thankYouDescription') }}</p>
    </div>
    <div class="container mt-0">
      <v-col cols="12" lg="12" class="text-center">
        <button
          @click="submit"
          class="main-button-gradient main-button-gradient-full-width semibold-font"
        >
          <span>{{ $t('done') }}</span>
          <button class="checked-mark-icon ml-2 inline-grid"></button>
        </button>
      </v-col>
    </div>
    <div class="container mt-0 mb-0 pb-0 pt-0 mt-0">
      <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
        <v-row class="mt-6">
          <v-col cols="2" class="mt-2">
            <span><img src='@/assets/icons/i-icon.svg' /></span>
          </v-col>
          <v-col cols="10" class="info-text">
            <span >{{ $t('thankYouInfo') }}</span>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  data () {
    return {
      acceptAgreement: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      return true
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  },
  methods: {
    submit () {
      this.loading = true
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'thank_you' })
        .then(response => {
          this.loading = false
          this.$router.push('/documents')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-grid {
    display: inline-grid;
    margin-top: 0px;
    margin-left: -13px;
    position: absolute;
  }

  .accept-agreement-checkbox {
    margin-top: -10px;

    label {
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: justify;
    }
  }
</style>
