<template>
  <div>
     <div class="text-center" v-if="documentsLoading">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <iframe v-if="!documentsLoading" :id="'JotFormIFrame-' + creationDocument.attributes.jotform_id" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allow="geolocation; microphone; camera; fullscreen" :src="'https://form.jotform.com/' + creationDocument.attributes.jotform_id + '?' + creationDocument.attributes.jotform_params"  frameborder="0" style="min-width:100%;max-width:100%;height:539px;border:none;" scrolling="true" > </iframe>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'jotform',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),
    ...mapGetters(['documentsLoading'])
  },
  mounted () {
    const jotformIds = [
      '242523026008344', '242532515068353', '242532819988371', '242551586499370',
      '242552304992357', '242553181129353', '242554382479365', '242592992213360',
      '242413279522353', '242251992538362', '242452147662355', '242412522103338',
      '242453107122343', '242343298578367', '242461525508354', '242392254997368',
      '242461813277357', '242473569374366', '242480840429357', '242462700830348',
      '242481859700360', '242463057038354', '242483266870362', '242472000557348',
      '241621404672349', '241711750427351', '241622746258359', '241702378708359',
      '241611928304351', '241691289033358', '241572973477368', '241630981531353',
      '241572964512358', '241702187127352', '241571366694364', '241701834984361',
      '241572572287362', '241631057981357', '241571287102350', '241631742352350',
      '241991943522361', '241981342756362', '241973133097359', '241971673915365',
      '241981552410352', '241982601148356', '241836249597370', '241822648940057',
      '241711847278059', '242062556741051'
    ]

    jotformIds.forEach(id => {
      window.jotformEmbedHandler(`iframe[id='JotFormIFrame-${id}']`, 'https://form.jotform.com/')
    })

    if (this.creationDocument) {
      this.$http.post(`/documents/${this.creatingDocumentState.document_id}/change_subprocess_step`, { data: { step_name: 'BPK_6' } })
        .then(response => {
          console.log(response)
        })
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
  }
}
</script>

<style lang="scss">
</style>
