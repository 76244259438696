<template>
  <div>
    <div class="container mt-0">
      <h4 class="semibold-font mb-1 mt-0 base-info-description">{{ $t('baseInfoIntroHeader') }}</h4>
    </div>
    <div class="container mt-0">
      <p cols="12" class="base-info-description">{{ $t('baseInfoAddressIntro') }}</p>
      <div class="gradient-divider">&nbsp;</div>
    </div>
    <div v-if="creationDocument" class="container mt-0">
      <form @submit.prevent="submit">
        <v-row>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('country') }}</div>
            <v-select
              v-model="creationDocument.attributes.country"
              class="outlined__input gradient-border"
              :items="countries"
              outlined
              required
            >
            </v-select>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="12" xs="5" sm="5">
            <div class="base-info-description mb-1">{{ $t('postcode') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              required
              v-model="creationDocument.attributes.postcode"
            ></v-text-field>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="12" xs="7" sm="7">
            <div class="base-info-description mb-1">{{ $t('city') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              required
              v-model="creationDocument.attributes.city"
            ></v-text-field>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('street') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              required
              v-model="creationDocument.attributes.street"
            ></v-text-field>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="5" sm="5">
            <div class="base-info-description mb-1">{{ $t('house_number') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              required
              v-model="creationDocument.attributes.house_number"
            ></v-text-field>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="7" sm="7">
            <div class="base-info-description mb-1">{{ $t('apartment_number') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              v-model="creationDocument.attributes.apartment_number"
            ></v-text-field>
          </v-col>
          <v-col class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('sameCorrespondenceAddress') }}</div>
            <v-radio-group inline class="inline-radio-buttons" v-model="sameCorrespondenceAddress">
              <v-radio class="inline-block" :label="$t('true')" :value="true"></v-radio>
              <v-radio class="inline-block ml-12" :label="$t('false')" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="!sameCorrespondenceAddress" class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('country') }}</div>
            <v-select
              v-model="creationDocument.attributes.correspondence_country"
              class="outlined__input gradient-border"
              :items="countries"
              :required="!sameCorrespondenceAddress"
              outlined
            >
            </v-select>
          </v-col>
          <v-col v-if="!sameCorrespondenceAddress" class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="5" sm="5">
            <div class="base-info-description mb-1">{{ $t('postcode') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              :required="!sameCorrespondenceAddress"
              v-model="creationDocument.attributes.correspondence_postcode"
            ></v-text-field>
          </v-col>
          <v-col v-if="!sameCorrespondenceAddress" class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="7" sm="7">
            <div class="base-info-description mb-1">{{ $t('city') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              :required="!sameCorrespondenceAddress"
              v-model="creationDocument.attributes.correspondence_city"
            ></v-text-field>
          </v-col>
          <v-col v-if="!sameCorrespondenceAddress" class="mt-0 mb-2 pt-0 pb-0" cols="12" sm="12">
            <div class="base-info-description mb-1">{{ $t('street') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              :required="!sameCorrespondenceAddress"
              v-model="creationDocument.attributes.correspondence_street"
            ></v-text-field>
          </v-col>
          <v-col v-if="!sameCorrespondenceAddress" class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="5" sm="5">
            <div class="base-info-description mb-1">{{ $t('house_number') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              :required="!sameCorrespondenceAddress"
              v-model="creationDocument.attributes.correspondence_house_number"
            ></v-text-field>
          </v-col>
          <v-col v-if="!sameCorrespondenceAddress" class="mt-0 mb-2 pt-0 pb-0 small-screen-full" cols="7" sm="7">
            <div class="base-info-description mb-1">{{ $t('apartment_number') }}</div>
            <v-text-field
              class="base-form-input"
              hide-details="true"
              outlined
              autofocus
              v-model="creationDocument.attributes.correspondence_apartment_number"
            ></v-text-field>
          </v-col>
          <v-col cols="6" lg="6" class="text-left small-screen-full">
            <button
              type="button"
              @click="undo"
              class="grey-text semibold-font"
            >{{ $t('undo') | uppercase }}</button>
          </v-col>
          <v-col cols="6" lg="6" class="text-right small-screen-full">
            <button
              :disabled="loading"
              type="submit"
              class="gradient-pink semibold-font"
            >{{ $t('next') | uppercase }}
            <v-progress-circular
              v-if="loading"
              :size="15"
              color="grey"
              indeterminate
            ></v-progress-circular>
            </button>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfoAddress',
  data () {
    return {
      loading: false,
      sameCorrespondenceAddress: true,
      countries: [
        { value: 'Austria', text: this.$i18n.t('countries.Austria') },
        { value: 'Belgium', text: this.$i18n.t('countries.Belgium') },
        { value: 'Bulgaria', text: this.$i18n.t('countries.Bulgaria') },
        { value: 'Denmark', text: this.$i18n.t('countries.Denmark') },
        { value: 'France', text: this.$i18n.t('countries.France') },
        { value: 'Netherlands', text: this.$i18n.t('countries.Netherlands') },
        { value: 'Ireland', text: this.$i18n.t('countries.Ireland') },
        { value: 'Germany', text: this.$i18n.t('countries.Germany') },
        { value: 'Norway', text: this.$i18n.t('countries.Norway') },
        { value: 'Poland', text: this.$i18n.t('countries.Poland') },
        { value: 'Romania', text: this.$i18n.t('countries.Romania') },
        { value: 'Sweden', text: this.$i18n.t('countries.Sweden') },
        { value: 'United Kingdom', text: this.$i18n.t('countries.United_Kingdom') },
        { value: 'Ukraine', text: this.$i18n.t('countries.Ukraine') },
        { value: 'Russia', text: this.$i18n.t('countries.Russia') },
        { value: 'Belarussia', text: this.$i18n.t('countries.Belarussia') }
      ]
    }
  },
  computed: {
    ...mapGetters(['creatingDocumentState']),
    ...mapGetters(['creationDocument']),

    validAllData () {
      if (!this.sameCorrespondenceAddress) {
        return this.creationDocument.attributes.country &&
          this.creationDocument.attributes.postcode &&
          this.creationDocument.attributes.city &&
          this.creationDocument.attributes.street &&
          this.creationDocument.attributes.house_number &&
          this.creationDocument.attributes.correspondence_country &&
          this.creationDocument.attributes.correspondence_postcode &&
          this.creationDocument.attributes.correspondence_city &&
          this.creationDocument.attributes.correspondence_street &&
          this.creationDocument.attributes.correspondence_house_number
      } else {
        return this.creationDocument.attributes.country &&
          this.creationDocument.attributes.postcode &&
          this.creationDocument.attributes.city &&
          this.creationDocument.attributes.street &&
          this.creationDocument.attributes.house_number
      }
    },

    emptyCorrespondenceAddress () {
      return this.creationDocument.attributes.correspondence_country === null &&
        this.creationDocument.attributes.correspondence_postcode === null &&
        this.creationDocument.attributes.correspondence_city === null &&
        this.creationDocument.attributes.correspondence_street === null &&
        this.creationDocument.attributes.correspondence_house_number === null
    },

    sameCorrespondenceAddresses () {
      return (this.creationDocument.attributes.country === this.creationDocument.attributes.correspondence_country) &&
        (this.creationDocument.attributes.postcode === this.creationDocument.attributes.correspondence_postcode) &&
        (this.creationDocument.attributes.city === this.creationDocument.attributes.correspondence_city) &&
        (this.creationDocument.attributes.street === this.creationDocument.attributes.correspondence_street) &&
        (this.creationDocument.attributes.house_number === this.creationDocument.attributes.correspondence_house_number) &&
        (this.creationDocument.attributes.apartment_number === this.creationDocument.attributes.correspondence_apartment_number)
    }
  },
  created () {
    this.$store.dispatch('getCreationDocument', this.creatingDocumentState.document_id)
    this.sameCorrespondenceAddress = this.emptyCorrespondenceAddress || this.sameCorrespondenceAddresses
  },
  methods: {
    submit () {
      this.loading = true
      const autoFillCorrespondenceAddress = this.emptyCorrespondenceAddress && this.sameCorrespondenceAddress
      const dataObject = {
        state: 'base_info_address',
        client: {
          country: this.creationDocument.attributes.country,
          postcode: this.creationDocument.attributes.postcode,
          city: this.creationDocument.attributes.city,
          street: this.creationDocument.attributes.street,
          house_number: this.creationDocument.attributes.house_number,
          apartment_number: this.creationDocument.attributes.apartment_number,
          correspondence_country: autoFillCorrespondenceAddress ? this.creationDocument.attributes.country : this.creationDocument.attributes.correspondence_country,
          correspondence_postcode: autoFillCorrespondenceAddress ? this.creationDocument.attributes.postcode : this.creationDocument.attributes.correspondence_postcode,
          correspondence_city: autoFillCorrespondenceAddress ? this.creationDocument.attributes.city : this.creationDocument.attributes.correspondence_city,
          correspondence_street: autoFillCorrespondenceAddress ? this.creationDocument.attributes.street : this.creationDocument.attributes.correspondence_street,
          correspondence_house_number: autoFillCorrespondenceAddress ? this.creationDocument.attributes.house_number : this.creationDocument.attributes.correspondence_house_number,
          correspondence_apartment_number: autoFillCorrespondenceAddress ? this.creationDocument.attributes.apartment_number : this.creationDocument.attributes.correspondence_apartment_number
        }
      }
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/update_document`, { data: dataObject })
        .then(response => {
          this.loading = false
          this.creatingDocumentState.state = 'base_info_address'
        })
        .catch((error) => {
          console.log(error)
        })
    },

    undo (event) {
      event.preventDefault()
      this.$http.put(`documents/${this.creatingDocumentState.document_id}/change_state`, { state: 'previous' })
        .then(response => {
          console.log(response)
          this.creatingDocumentState.state = response.data.state
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .inline-radio-buttons {
    display: flex;
    justify-content: space-between;

    .v-input--radio-group__input {
      display: inline-block;
    }
  }

  /* Custom responsive breakpoint for very small screens */
  @media (max-width: 369px) {
    .small-screen-full {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
  }
</style>
